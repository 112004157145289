export default {
  "404": {
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ошибка 404 — Страница не найдена"])},
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Веб-сайт, который вы ищете, не найден."])}
  },
  "footer": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A-Wallet — кошелек сообщества PWA Algorand с открытым исходным кодом"])}
  },
  "login": {
    "new_wallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Новый кошелек"])},
    "new_wallet_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Имя кошелька"])},
    "new_wallet_pass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пароль кошелька"])},
    "new_wallet_button_create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создать кошелек"])},
    "new_wallet_button_import": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Импортировать кошелек"])},
    "new_wallet_help1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ваш кошелек будет храниться в вашем браузере. Пароль кошелька необходим для открытия кошелька и просмотра учетных записей в кошельке, а также для подписания транзакций."])},
    "new_wallet_help2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мы рекомендуем длину пароля 12 или более символов, используя строчные и прописные буквы, цифры и специальные символы."])},
    "open_wallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Открыть кошелек"])},
    "select_wallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выбрать кошелек"])},
    "wallet_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пароль кошелька"])},
    "strength": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сила"])},
    "new_wallet_button_open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Открыть кошелек"])},
    "basic_usage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Базовое использование"])},
    "tether_usage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Как использовать Tether (USDt)"])},
    "source_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Исходный код AWallet"])}
  },
  "global": {
    "go_back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вернуться назад"])},
    "go_home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Домой"])},
    "copy_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скопировать address"])},
    "copied_to_clipboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скопировано в буфер обмена"])},
    "stop_camera": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Остановить камеру"])},
    "отмена": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отмена"])},
    "да": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Да"])},
    "нет": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет"])},
    "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Загрузка.."])},
    "last_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Последняя ошибка"])},
    "keyword_search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поиск по ключевым словам"])}
  },
  "navbar": {
    "logo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кошелек"])},
    "toggle_nav": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Переключить навигацию"])},
    "list_accounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мои аккаунты"])},
    "new_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Новая учетная запись"])},
    "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Настройки"])},
    "asset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asset"])},
    "asset_create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создать NFT"])},
    "FAQ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FAQ"])},
    "privacy_policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Политика конфиденциальности"])},
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выход"])},
    "vote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Голосование"])},
    "swap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SWAP"])},
    "connect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WalletConnect"])}
  },
  "swap": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обменять активы"])},
    "network": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сеть"])},
    "network_not_supported": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сеть не поддерживается"])},
    "has_sk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сейчас поддерживаются только стандартные учетные записи с закрытыми ключами"])},
    "swap_asset_from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите актив, из которого вы хотите обменять"])},
    "swap_asset_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите актив, на который вы хотите обменять"])},
    "swap_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Установите сумму для обмена"])},
    "get_quote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Получить цитату"])},
    "apps_optin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SWAP требует, чтобы ваша учетная запись согласилась на использование приложений"])},
    "apps_optin_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Включить использование приложений"])},
    "execute_button_deflex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выполнить с использованием Deflex"])},
    "execute_button_folks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выполнить с использованием маршрутизатора Folks"])},
    "slippage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Процент проскальзывания"])}
  },
  "govtoolsmenu": {
    "gen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создать аккаунты"])},
    "distribute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Алгоритм распределения 1->N"])},
    "optin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Принять участие в управлении"])},
    "pay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Массовая выплата N->1"])},
    "merge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Объединить аккаунты N->1"])}
  },
  "merchant": {
    "make_paying": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Платежный шлюз"])},
    "pay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оплатить"])},
    "to_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["по addressу алгоритма"])},
    "please": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пожалуйста"])},
    "network": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сеть"])},
    "matching_symbol": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Соответствующий символ"])},
    "network_fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сетевая плата"])},
    "pay_qr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оплатите с помощью QR-кода"])},
    "pay_nativewallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оплата с помощью кошелька Algorand"])},
    "pay_webwallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оплата с помощью веб-кошелька"])},
    "cancel_paying": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отменить платеж"])},
    "pay_received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Платеж получен"])},
    "go_back_to_merchant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вернуться к торговцу"])},
    "waiting_for_paying": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ожидание платежа"])},
    "error_asset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ошибка при получении актива: "])}
  },
  "gateway": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бесплатный платежный шлюз с открытым исходным кодом без посредников"])},
    "perex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Начните принимать Algo, USDC, USDT или другие активы на своем веб-сайте. Принимая криптовалюту без финансового посредника, вы увеличите свои продажи на своем веб-сайте и увеличите свою прибыль."])},
    "how": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Как это работает?"])},
    "create_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создайте уникальный соответствующий символ, например идентификатор заказа"])},
    "redirect_to_gateway": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Перенаправить пользователя на шлюз"])},
    "customer_pays": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Клиент платит непосредственно на ваш счет в Algorand"])},
    "customer_redirected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["После успешной оплаты клиент будет перенаправлен обратно на ваш сайт"])},
    "use_your_money": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Используйте свои деньги. Вы можете обменять USDT и получить доллары США с биржи, или вы можете платить USDT за другие товары или услуги"])},
    "security": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Это безопасно?"])},
    "you_make_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы вводите данные платежа. Вы настраиваете успешный URL-address и отменяете его. Мы уведомляем вас, когда платеж будет выполнен. Вам необходимо проверить в своем бэкэнде, где вы принимаете платеж, что он был получен, и вы уведомляете клиент."])},
    "opensource": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Этот проект с открытым исходным кодом. Вы можете клонировать, изменять или запускать его в своей частной среде в коммерческих целях"])},
    "just_website": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Платежный шлюз — это просто веб-сайт HTML с JavaScript. Никакой другой бэкэнд, кроме официальных узлов алгоритма, не используется."])},
    "no_restrictions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Для использования шлюза вам не нужна учетная запись AWallet."])},
    "allowed_parameters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Разрешенные параметры"])},
    "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["используйте базовую единицу актива. Например, 1 ALGO = 1000000"])},
    "fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["сумма в микроалго. Например, 0,001 ALGO = 1000"])},
    "network": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сеть для целей отладки"])},
    "settings_params": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Разрешенные настройки"])},
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL-address, на который перенаправляется клиент после успешной оплаты"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL-address, на который перенаправляется клиент после нажатия кнопки 'Отменить платеж'"])},
    "example": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Например"])},
    "turn_on_build_tool": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Включить инструмент сборки"])},
    "turn_off_build_tool": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отключить инструмент сборки"])},
    "tether": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Основная сеть Tether"])},
    "algorand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Algorand"])},
    "asset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы можете указать идентификатор актива для транзакции"])},
    "error_transaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пожалуйста, укажите хотя бы счет, сумму и актив"])},
    "error_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL-address должен начинаться с https://"])},
    "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сгенерированная кнопка"])},
    "Payment_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Информация о платеже"])},
    "callback_configuration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Конфигурация обратного вызова"])},
    "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сгенерированная ссылка"])},
    "html_example": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пример ссылки HTML"])}
  },
  "receive": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Получить платеж"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Получить платеж на address"])},
    "сумма": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сумма"])},
    "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Примечание"])},
    "decimals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Информация о десятичном знаке в QR-коде"])},
    "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Предлагаемая метка addressа получателя"])},
    "noteeditable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заметка может редактироваться пользователем"])}
  },
  "acc_overview": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обзор аккаунта"])},
    "pay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Совершить новый платеж"])},
    "receive_paying": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Получить платеж"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Имя"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["address"])},
    "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сумма"])},
    "amount_without_pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сумма без ожидающих вознаграждений"])},
    "rewards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Награды"])},
    "pending_rewards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ожидающие награды"])},
    "reward_base": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["База вознаграждений"])},
    "round": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Round"])},
    "apps_local_state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Локальное состояние приложений"])},
    "apps_total_schema": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Общая схема приложений"])},
    "assets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Активы"])},
    "created_apps": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Созданные приложения"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статус"])},
    "multisignature_threshold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Порог мультиподписи"])},
    "multisignature_addresses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["addressа с несколькими подписями"])},
    "refresh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обновить"])},
    "transactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Транзакции"])},
    "no_transactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Транзакции не найдены"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тип"])},
    "time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Время"])},
    "tr_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сумма"])},
    "sender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отправитель"])},
    "receiver": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Получатель"])},
    "receiver_rewards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Получатель вознаграждений"])},
    "confirmed_round": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Раунд подтвержден"])},
    "fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Плата"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удалить аккаунт"])},
    "delete_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удалить аккаунт"])},
    "delete_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы уверены, что хотите удалить эту учетную запись из своего кошелька?"])},
    "delete_confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удалить эту учетную запись"])},
    "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Аккаунт был удален"])},
    "asset_optin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Включить актив"])},
    "ask_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Задать вопрос"])},
    "rekey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изменить ключ аккаунта"])},
    "rekeyedTo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ключ к аккаунту изменен"])},
    "account0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Основной аккаунт книги"])},
    "slot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Счет в слоте реестра"])},
    "connect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WalletConnect"])},
    "Payment_gateway": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Платежный шлюз"])},
    "export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Экспорт"])},
    "no_assets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["На этом аккаунте пока нет активов"])},
    "actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Действия"])},
    "pay_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отправляйте деньги или токены на другие аккаунты в сети."])},
    "swap_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы можете обменять один токен на другой с помощью сервисов агрегатора DEX"])},
    "rekey_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Если вы боитесь, что закрытый ключ вашей учетной записи может быть скомпрометирован, вы можете изменить ключ своей учетной записи на новый закрытый ключ и сохранить свой первоначальный address в безопасности."])},
    "asset_optin_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Если вы хотите получать активы в сети Algorand, вам необходимо открыть учетную запись для этого токена."])},
    "receive_pay_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы можете попросить своих клиентов или друзей отправить вам деньги. На этой странице будет сгенерирован QR-код платежа, чтобы другие могли сделать это одним щелчком мыши."])},
    "export_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы можете создать мнемонику резервного копирования Shamir или мнемонику Algorand для учетных записей с закрытыми ключами."])},
    "arc14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Аутентификация ARC 14"])},
    "arc14_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ARC 14 — это стандарт аутентификации, использующий подписанные транзакции. На этой странице вы можете сгенерировать токены аутентификации."])},
    "account_actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Действия с аккаунтом"])},
    "asset_optinArc200": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подключиться к активу ARC200"])},
    "asset_optinArc200_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы можете наблюдать за активами ARC200 в своей учетной записи. Активы ARC200 — это смарт-контракты с функцией, которую каждый может выбрать в учетной записи пользователя, чтобы получить актив. Активы ARC200 могут быть опасными, и вам следует потребовать заслуживающего доверия аудита смарт-контракт, чтобы вы могли полностью ему доверять."])},
    "scheduled_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scheduled payment"])},
    "scheduled_payment_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can setup and manage a scheduled periodic payments to another accounts"])},
    "hide_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hide account"])},
    "unhide_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unhide account"])},
    "vote_first_round": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vote First Round"])},
    "vote_last_round": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vote Last Round"])},
    "vote_key_dilution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vote Key Dilution"])},
    "selection_key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selection Key"])},
    "vote_key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vote Key"])},
    "stateproof_key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["StateProof Key"])},
    "realm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Realm"])},
    "arc14msig_process": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please sign ARC14 authentication transaction and return back to this form."])},
    "hasArc14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ARC14 auth has been loaded."])},
    "generating_keys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generating participation keys.. Please be patient, the participation node is performing CPU sensitive task."])},
    "button_sign_keyreg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign key registration tx"])},
    "button_close_custom_keyreg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close KeyReg tx form"])},
    "button_activate_wizzard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activate wizard"])},
    "button_fetch_realm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Step 1/4: Fetch ARC14 realm"])},
    "button_sign_arc14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Step 2/4: Sign ARC14 realm"])},
    "button_sign_arc14_msig": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Step 2/4: Multisign ARC14 realm"])},
    "button_load_participatin_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Step 3/4: Load participation data"])},
    "button_sign_keyreg_tx": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Step 4/4: Sign participation tx"])},
    "button_custom_keyreg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom KeyReg tx"])},
    "making_account_online": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Setting your account to online state. Please wait a while"])},
    "making_account_offline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Setting your account to offline state. Please wait a while"])}
  },
  "acc_overview_assets": {
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Имя"])},
    "search_by_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поиск по имени"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тип"])},
    "search_by_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поиск по типу актива"])},
    "ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])},
    "search_by_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поиск по идентификатору актива"])},
    "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сумма"])},
    "search_by_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поиск по сумме"])},
    "actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Действия"])}
  },
  "optin": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Принимать переводы активов на счет"])},
    "searchById": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Я знаю идентификатор актива"])},
    "assetId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Идентификатор актива"])},
    "assetName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Имя актива"])},
    "searchButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Найти актив"])},
    "assetInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Информация об активе"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Итого"])},
    "unit_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Имя юнита"])},
    "decimals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Десятичные числа"])},
    "URL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL"])},
    "metadata_hash": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hash метаданных"])},
    "default_frozen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заморожено по умолчанию"])},
    "creator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["address создателя"])},
    "manager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["address менеджера"])},
    "clawback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["address возврата"])},
    "freeze": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заморозить address"])},
    "reserve": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Резервный address"])},
    "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Действие"])},
    "optin_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Принять участие"])},
    "dialog_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Включить актив"])},
    "optin_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы уверены, что хотите заплатить 0,001 алгоритма, чтобы подписаться на этот актив? После того, как вы согласитесь, вы сможете получить этот актив на свой аккаунт."])},
    "optin_confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Я хочу подписаться"])},
    "asset_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Актив не найден"])},
    "asset_opt_in_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Актив активирован успешно"])}
  },
  "accounts": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обзор аккаунтов"])},
    "no_accounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Аккаунты не найдены"])},
    "account_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Имя аккаунта"])},
    "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сумма"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["address"])},
    "pay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оплатить"])},
    "connect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WalletConnect"])},
    "show_on_netowork_accounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Показать аккаунты в сети"])},
    "2fa_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Код 2FA"])}
  },
  "newacc": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Новая учетная запись"])},
    "create_basic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создать базовую учетную запись"])},
    "import_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Импортировать аккаунт"])},
    "create_multisign_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создать мультиподписную учетную запись"])},
    "watch_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Смотреть аккаунт"])},
    "write_mnemonic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Запишите мнемоническую фразу из 25 слов"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Внутреннее имя учетной записи"])},
    "create_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создать аккаунт"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["address"])},
    "multisig_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Учетная запись с несколькими подписями может обрабатывать транзакции, только если N учетных записей, перечисленных при создании учетной записи, подпишут транзакцию."])},
    "select_account_from_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите существующие учетные записи в вашем кошельке"])},
    "add_other_accounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавьте учетные записи своих друзей — по одной учетной записи в строке"])},
    "trashold_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите, сколько аккаунтов необходимо для подписи транзакции"])},
    "create_account_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Чтобы создать учетную запись, обязательно сделайте резервную копию мнемонической фразы. Безопасно ли показывать эту фразу сейчас?"])},
    "show_mnemonic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Показать мнемонику"])},
    "position_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Какое слово стоит на позиции n."])},
    "mnemonic_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Запишите мнемонические слова в отображаемом порядке, скопируйте или сфотографируйте. Следующим шагом будет подтверждение некоторых слов из этой фразы, и мы сохраним их в зашифрованном хранилище вашего браузера. Это безопасно. чтобы сохранить эту мнемонику и использовать этот address, не сохраняя его в учетной записи. Никогда не потеряйте мнемоническую фразу."])},
    "start_challenge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Начать испытание"])},
    "create_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создать новое"])},
    "hide_mnemonic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скрыть мнемонику"])},
    "drop_phrase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удалить фразу"])},
    "scan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сканировать по QR-коду"])},
    "create_vanity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создать личный аккаунт"])},
    "vanity_start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["address будет начинаться с"])},
    "vanity_mid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["address будет содержать"])},
    "vanity_end": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["address будет заканчиваться на"])},
    "vanity_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Количество обработанных addressов"])},
    "vanity_button_start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Найти address"])},
    "vanity_button_stop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Прекратить поиск"])},
    "vanity_workers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Количество используемых потоков"])},
    "vanity_use": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Использовать эту учетную запись"])},
    "auto_lock_off": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Функция автоматической блокировки отключена"])},
    "ledger_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HW Wallet – учетная запись Ledger"])},
    "wc_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Учетная запись подключения к кошельку"])},
    "twofa_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Учетная запись 2FA"])},
    "show_qr_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Показать QR-код"])},
    "email_pass_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Аккаунт электронной почты и пароля"])},
    "shamir_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Восстановление резервной копии Шамира"])},
    "overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Действия с новой учетной записью"])},
    "create_basic_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Базовая учетная запись Algorand — это учетная запись ED25519 с закрытым ключом. Этот метод использует генератор случайных чисел вашего компьютера для создания секрета для вашей учетной записи."])},
    "ledger_account_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Одним из наиболее безопасных решений является обработка секретного ключа с помощью специального устройства. Эта учетная запись использует устройство Ledger для создания вашей учетной записи и выполнения операций подписи."])},
    "wc_account_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы можете подключиться к кошельку вашего друга, используя тип учетной записи подключения к кошельку. Вы можете отправить ему запрос на подпись сообщения, например, как часть мультиподписи."])},
    "twofa_account_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Двухфакторная учетная запись — это учетная запись с мультиподписью, где одна учетная запись — это учетная запись по вашему выбору, например, учетная запись бухгалтерской книги или учетная запись с паролем электронной почты, вторая учетная запись — это учетная запись восстановления — в идеале учетная запись, которая не находится в том же безопасном хранилище, а третья учетная запись — двухфакторная учетная запись с сервера, которая подписывает транзакции, когда вы предоставляете правильный двухфакторный пин-код."])},
    "email_pass_account_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Учетная запись электронной почты и пароля ARC76 позволяет вам генерировать учетную запись алгоритма из любого текста. Текст хешируется и генерирует 32 байта в качестве начального числа для вашей учетной записи алгоритма. Это может быть альтернативой другим типам генераторов случайных чисел на вашем компьютере или hw-устройство, которое вы можете использовать в двухфакторной учетной записи или общей учетной записи с мультиподписью."])},
    "create_vanity_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы можете подобрать красивый address учетной записи для вашей учетной записи ED25519. Этот метод просто пробует множество комбинаций создания учетной записи и позволяет вам выбрать первые или последние буквы addressа учетной записи."])},
    "create_multisign_account_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мультиподписные учетные записи являются наиболее безопасными учетными записями. При использовании в сочетании с несколькими устройствами реестра и различными генераторами случайных чиселЭто обеспечивает корпоративный уровень безопасности, который жестко запрограммирован непосредственно в блокчейне алгоритмов."])},
    "watch_account_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы можете отслеживать любую учетную запись в сети."])},
    "import_account_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы можете восстановить учетную запись с помощью мнемоники восстановления Algorand."])},
    "shamir_account_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы можете восстановить учетную запись с помощью мнемоники восстановления резервной копии Shamir."])},
    "create_first": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создайте свою первую учетную запись"])},
    "no_accounts_at_network": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Для выбранной сети нет учетных записей"])},
    "basic_options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Основные параметры"])},
    "advanced_options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дополнительные параметры"])},
    "skip_challange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skip challange"])},
    "new_account_challange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New account challange"])},
    "click_to_show_positions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click to show positions"])},
    "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["to"])}
  },
  "arc76account": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["address электронной почты и пароль учетной записи (ARC-0076)"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Хеш комбинации вашего addressа электронной почты и пароля будет генерировать начальные байты для вашей первой учетной записи Algorand. Вы можете изменить пароль или address электронной почты, только переключив ключ на другую учетную запись."])},
    "description2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Стандарт ARC-0076 будет использоваться с алгоритмом PBKDF2-999999-SHA256. Мы рекомендуем длину пароля не менее 50 символов. Этот тип учетной записи может использоваться в качестве генератора случайных чисел вторичного типа и может усилить вашей безопасности. Пожалуйста, храните секрет в безопасности и не используйте его повторно."])},
    "arc_draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ARC-0076 находится в состоянии ЧЕРНОВИК. Спецификация может измениться. Пожалуйста, рассмотрите эту реализацию для экспериментального использования."])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Электронная почта"])},
    "select_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пожалуйста, выберите очень-очень надежный пароль."])},
    "save_password_switch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сохранить пароль"])},
    "password_not_stored": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Осторожно. Мы не храним ваш пароль. Мы будем хранить в безопасном хранилище только публичный address учетной записи и address электронной почты. Когда вы будете совершать транзакции с этой учетной записью, вам будет предложено ввести пароль."])},
    "gui_not_implemented": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Графический интерфейс для подписания транзакции без сохраненного пароля в настоящее время не реализован."])}
  },
  "Import": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Импортировать кошелек"])},
    "wallet_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Имя кошелька"])},
    "wallet_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Файл кошелька"])},
    "import_wallet_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Импортировать кошелек"])},
    "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы можете импортировать ранее экспортированные кошельки. Пароль кошелька остается в экспортированном файле, поэтому вы можете получить к нему доступ после импорта с тем же паролем, что и раньше. Вы можете изменить пароль после входа в систему на странице настроек."])}
  },
  "acc_type": {
    "basic_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Базовый аккаунт"])},
    "multisig_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Учетная запись с несколькими подписями"])},
    "public_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Публичная учетная запись"])},
    "rekeyed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Учетная запись изменена"])},
    "ledger_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Счет главной книги HW"])},
    "wc_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Учетная запись WalletConnect"])}
  },
  "account": {
    "select_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выбрать аккаунт"])}
  },
  "pay": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оплатить - от"])},
    "toggle_camera": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Переключить камеру"])},
    "note_is_b64": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Примечание имеет кодировку Base64. Если эта опция включена, вы можете опубликовать вводимые данные в формате Base64 в поле примечания и отправить его в сеть в виде необработанных данных."])},
    "selected_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выбранная учетная запись"])},
    "multisig_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Учетная запись с несколькими подписями"])},
    "create_proposal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создать предложение"])},
    "sign_proposal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подписать и отправить предложение"])},
    "signature_from_friend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пожалуйста, введите здесь подпись вашего друга"])},
    "load_multisig_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Загрузить данные мультиподписи"])},
    "pay_to_wallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оплата на счет кошелька"])},
    "pay_to_other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оплатить на другой счет"])},
    "store_other_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Счет, на который вы отправляете деньги, лучше хранить в addressной книге"])},
    "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сумма"])},
    "fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Плата"])},
    "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Примечание"])},
    "review_paying": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Просмотреть платеж"])},
    "review_paid_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пожалуйста, проверьте ваш платеж"])},
    "from_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Из аккаунта"])},
    "pay_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оплатить на счет"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Итого"])},
    "transaction_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Детали транзакции"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тип"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Имя"])},
    "first_round": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Первый раунд"])},
    "last_round": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Последний раунд"])},
    "genesis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Идентификатор Genesis"])},
    "tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тег"])},
    "to_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кому"])},
    "signature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подписи"])},
    "signed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подписано"])},
    "not_signed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не подписано"])},
    "sign_with": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Войти с помощью"])},
    "sing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подпись"])},
    "send_to_other_signators": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отправьте эти данные другим подписавшим"])},
    "send_to_network": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отправить в сеть"])},
    "state_sending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отправка платежа в сеть"])},
    "state_sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Платеж отправлен в сеть. Tx: "])},
    "state_waiting_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ожидание подтверждения сети."])},
    "state_confirmed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подтверждение получено. Ваш платеж в блоке"])},
    "transaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Транзакция"])},
    "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ошибка"])},
    "asset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Актив"])},
    "state_error_not_sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Платеж, вероятно, не дошел до сети. Вы не в сети? Пожалуйста, проверьте свою учетную запись"])},
    "set_max": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Установить максимум"])},
    "environment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Environment"])},
    "pay_to_address_malformed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["address оплаты, кажется, имеет неправильный формат."])},
    "asset_failed_to_load": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Похоже, что нужный актив не удалось загрузить"])},
    "asset_too_small_balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кажется, ваш баланс ниже желаемой суммы выплаты"])},
    "rekey_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сменить ключ для учетной записи"])},
    "rekey_to_wallet_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Переключите ключ на учетную запись кошелька"])},
    "rekey_to_external_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Переключить ключ на внешнюю учетную запись"])},
    "rekey_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изменить ключ аккаунта"])},
    "rekey_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пожалуйста, обратите особое внимание на эту транзакцию. С этого момента учетная запись будет использовать другой закрытый ключ для выполнения транзакций. Если у вас нет закрытого ключа новой учетной записи, вы, скорее всего, потеряете возможность подписывать транзакции."])},
    "combine_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Объединить подписи другого подписывающего лица"])},
    "combine_action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Объединить подписи"])},
    "return_to_wc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вернуться в WalletConnect"])},
    "select_account_for_paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите счет, с которого вы хотите произвести оплату"])},
    "create_multisig_proposal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создать предложение с мультиподписью"])},
    "2fa_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Код 2FA"])},
    "sign_arc14_request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Запрос на авторизацию подписи"])},
    "toggle_send_to_others_form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Переключить форму отправки другим"])},
    "toggle_combine_with_others_form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Переключить форму объединения с другими"])},
    "process_paying": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обработка платежа"])},
    "tx_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тип транзакции"])}
  },
  "settings": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Настройки"])},
    "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сервер"])},
    "environment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Environment"])},
    "mainnet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Основная сеть"])},
    "testnet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тестнет"])},
    "devnet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добраться до Девнета"])},
    "sandbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sandbox"])},
    "host": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["host"])},
    "token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["token"])},
    "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Язык"])},
    "pass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пароль кошелька"])},
    "oldpass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Старый пароль"])},
    "newpass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Новый пароль"])},
    "repeatpass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подтвердите новый пароль"])},
    "backup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Резервный кошелек"])},
    "backup_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы можете сделать резервную копию своего кошелька и импортировать его на другой компьютер."])},
    "create_backup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создать резервную копию"])},
    "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Загрузить"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удалить кошелек"])},
    "strenght": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Strenght"])},
    "updated_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пароль обновлен"])},
    "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пользовательские настройки сервера"])},
    "protocol_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зарегистрировать обработчик протокола"])},
    "protocol_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зарегистрировать обработчик web+algorand://"])},
    "protocol_change_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обработчик протокола успешно обновлен"])},
    "dev_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Показать информацию для разработчиков"])},
    "update_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обновить пароль"])}
  },
  "Transaction": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Детали транзакции"])},
    "tr_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Идентификатор транзакции"])},
    "tr_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тип транзакции"])},
    "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Примечание"])},
    "note_raw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Необработанные данные"])},
    "note_decoded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Декодированные данные"])},
    "tr_close_rewards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Закрыть награды"])},
    "closing_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Завершающая сумма"])},
    "confirmed_round": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Раунд подтвержден"])},
    "fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Плата"])},
    "first_valid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Первый действительный"])},
    "genesis_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Идентификатор Genesis"])},
    "genesis_hash": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Хеш Бытия"])},
    "intra_round": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Смещение внутри раунда"])},
    "last_valid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Последний действительный"])},
    "Payment_tr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Платежная транзакция"])},
    "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сумма"])},
    "close_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сумма закрытия"])},
    "receiver": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Получатель"])},
    "receiver_rewards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Получатель вознаграждений"])},
    "round_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Время раунда"])},
    "sender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отправитель"])},
    "sender_rewards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Награды отправителя"])},
    "signature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подпись"])},
    "created_asset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создан актив"])},
    "asset_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Имя актива"])},
    "asset_creator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создатель актива"])},
    "asset_manager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Менеджер активов"])},
    "asset_reserve": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Резерв активов"])},
    "asset_freeze": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Замораживание активов"])},
    "asset_clawback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Возврат актива"])},
    "asset_unitName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Имя единицы актива"])},
    "asset_total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Общая сумма активов"])},
    "asset_decimals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Десятичные знаки актива"])},
    "asset_defaultfrozen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Актив по умолчанию заморожен"])},
    "asset_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL-address актива"])},
    "asset_metadata": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Метаданные актива"])},
    "time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Время"])},
    "group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Идентификатор группы"])}
  },
  "privacy": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Политика конфиденциальности"])},
    "q1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Какую личную информацию обо мне вы собираете?"])},
    "a1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет. Помимо IP-addressа и браузера, которые хранятся в журналах для каждой интернет-службы, мы не собираем никакой личной информации, такой как ваше имя, адрес электронной почты или адреса."])},
    "q2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Что вы храните в файлах cookie?"])},
    "a2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ничего. Мы не используем файлы cookie. Мы используем постоянное локальное хранилище под названием IndexedDB для хранения вашего кошелька."])},
    "q3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Как вы собираете аналитическую информацию?"])},
    "a3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мы нет. Мы не используем какие-либо аналитические службы, например, Google Analytics, чтобы вы могли чувствовать себя в большей безопасности. Веб-сайт создан исключительно на HTML с JavaScript. Мы знаем ваш IP-address только тогда, когда вы делаете запросы к В зависимости от ваших настроек и вашего алгоритмического сервера или индексатора выбранный сервер также может знать ваш IP-address."])},
    "q4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Влияет ли использование этой услуги на рекламу, которую я вижу?"])},
    "a4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет. Рекламные серверы не получают от нас никакой информации о каком-либо использовании. Единственный способ, например, Google, узнать, что вы используете эту услугу, — это получить доступ к этому сайту через поиск Google."])},
    "q5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кто предоставляет эту услугу?"])},
    "a5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scholtz & Company, jsa создала эту услугу. Идентификатор компании: 51 882 272, идентификатор плательщика НДС: SK2120828105, штаб-квартира в Братиславе, Словакия. Если она работает на официальном сайте a-wallet.net, компания также поставщик услуг. Компания не несет ответственности за какие-либо услуги или ошибки в приложении."])}
  },
  "assetcreate": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Новый NFT/ASA"])},
    "show_advanced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Показать расширенную форму"])},
    "assetName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дружественное имя"])},
    "creator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["address создателя"])},
    "hide_advanced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скрыть расширенную форму"])},
    "creator_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создать addressаили сначала импортируйте address"])},
    "default_fronzen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заморожено: учетные записи пользователей необходимо будет разморозить перед транзакцией"])},
    "decimals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Decimals: целое число десятичных знаков для расчета единицы актива"])},
    "totalIssuance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Общее количество этого актива, доступного для обращения"])},
    "unitName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Используется для отображения единиц активов пользователю"])},
    "assetURL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Необязательный URL-address, относящийся к активу"])},
    "assetMetadataHash": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Необязательное хэш-фиксирование какого-либо вида, относящееся к активу. Формат Base64"])},
    "manager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Менеджер может изменять учетную запись управляющего, резервировать, замораживать и отзывать актив для актива."])},
    "reserve": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Резервный address: указанный address считается резервным активом (он не имеет особых привилегий, носит только информационный характер)"])},
    "freeze": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заморозить address: указанный address может заморозить или разморозить активы пользователя"])},
    "clawback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["address возврата: указанный address может отозвать активы пользователя и отправить их на другие addressа"])},
    "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Примечание установлено в транзакции создания"])},
    "create_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создать NFT/ASA"])}
  },
  "new_account_ledger": {
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить учетную запись Ledger"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ledger — это физическое устройство – аппаратный кошелек, в котором может храниться ваш закрытый ключ. Максимальная безопасность хранения активов в Algorand — это учетная запись с мультиподписью, где все подписывающие стороны защищены устройствами реестра."])},
    "last_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Последняя ошибка"])},
    "connect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подключить реестр и загрузить address алгоритма"])},
    "slot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Слот"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["address"])},
    "primary_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Основной address"])},
    "account_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Имя аккаунта"])},
    "save_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сохранить address в кошельке"])}
  },
  "FAQ": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FAQ"])},
    "q1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["О AWallet"])},
    "a1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кошелёк Algo изначально был создан компанией Scholtz & Company, jsa. Открытый исходный код, только HTML, корпоративный подход и безопасность являются основными преимуществами его использования. Логотип Algorand, слова 'algo' и 'algorand' являются товарными знаками Algorand, Inc. и используются в соответствии с рекомендациями Algorand для СМИ https://www.algorand.com/about/media-kit/ ."])},
    "q2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Как защищены данные моего кошелька?"])},
    "a2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ваши данные находятся только в браузере. Они хранятся в постоянной IndexedDB. Каждый кошелек шифруется с использованием aes256 с вашим паролем. Если вы создадите достаточно надежный пароль, ваш кошелек никогда не будет взломан. Ваш пароль не будет взломан. не отходить от компьютера и находится только в кеше браузера. Кэш уничтожается через 5 минут после входа в систему."])},
    "q3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пароль моего кошелька выходит в Интернет?"])},
    "a3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Никогда"])},
    "q4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Как я могу изменить пароль моего кошелька?"])},
    "a4_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Войдите и получите доступ к своему кошельку."])},
    "a4_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Перейти к настройкам."])},
    "a4_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введите старый пароль, новый пароль и подтвердите новый пароль."])},
    "a4_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отправьте форму, и ваш пароль будет изменен."])},
    "q5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Могу ли я перенести кошелек на другой компьютер?"])},
    "a5_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Войдите и получите доступ к своему кошельку."])},
    "a5_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Перейти к настройкам."])},
    "a5_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Перейдите в раздел резервного копирования и сохраните данные в локальный файл."])},
    "a5_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["На другом компьютере перейдите в новый кошелек, импортируйте кошелек и загрузите загруженный файл в хранилище другого браузера."])},
    "a5_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Войдите в кошелек с тем же паролем, что и раньше. Вы можете изменить пароль в настройках."])},
    "q6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Могу ли я просмотреть исходный код?"])},
    "a6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Да. https://github.com/scholtz/wallet/ Этот кошелек создан с открытым исходным кодом и его можно бесплатно использовать, копировать или изменять по любой причине, в том числе в коммерческих целях. Если вы можете принять участие в проекте, пожалуйста, будьте любезны и создайте запрос на включение вашего улучшения."])},
    "q7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Чем я могу помочь проекту?"])},
    "a7_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пожертвование на address algorand AWALLETCPHQPJGCZ6AHLIFPHWBHUEHQ7VBYJVVGQRRY4MEIGWUBKCQYP4Y поможет проекту продолжаться. Проект не финансировался ни Algorand Inc., ни Algorand Foundation."])},
    "a7_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Чтобы перевести проект или помочь с переводом, перейдите по addressу https://github.com/scholtz/wallet/tree/master/src/locales, скопируйте файл перевода на свой язык и сделайте запрос на включение ."])},
    "a7_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Если вы программист, пожалуйста, сделайте обзор кода или помогите нам любым способом, который, по вашему мнению, должен быть лучше."])},
    "q8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Как создать учетную запись с мультиподписью?"])},
    "a8_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сначала создайте учетную запись с закрытыми ключами или создайте общедоступную учетную запись. Лучше дать им имена, которые помогут вам запомнить их назначение."])},
    "a8_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Перейдите к созданию нового addressа и выберите address с мультиподписью."])},
    "a8_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите все учетные записи, которые могут подписывать транзакции, и выберите количество подписей, соответствующее минимуму, позволяющему сети принимать платеж с мультиподписью."])},
    "a8_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["После создания учетной записи с мультиподписью вы сможете получить к ней доступ со страницы списка учетных записей."])}
  },
  "connect": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подключайтесь к децентрализованным приложениям с помощью WalletConnect"])},
    "uri": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URI WalletConnect"])},
    "connect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подключиться"])},
    "disconnect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отключить"])},
    "sendBack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отправить обратно в DApp"])},
    "reject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отклонить"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["address"])},
    "or": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["или"])},
    "authaddr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Авторизованный address"])},
    "index": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Индекс"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тип"])},
    "from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["От"])},
    "asset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Актив"])},
    "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сумма"])},
    "rekeyto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сменить ключ"])},
    "method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Метод"])},
    "client_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Идентификатор клиента"])},
    "request_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Идентификатор запроса"])},
    "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удалить"])},
    "peer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["peer"])},
    "connected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подключено"])},
    "sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сеансы"])},
    "requsts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Запросы"])},
    "fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Плата"])},
    "total_fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Общая комиссия"])},
    "sign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подпись"])},
    "clipboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подключиться из буфера обмена"])},
    "sign_txs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пожалуйста, подпишите отдельные транзакции"])},
    "signed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подписано"])},
    "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кому"])},
    "validity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["validity"])},
    "rounds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["раунды"])},
    "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Примечание"])},
    "group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Группа"])},
    "app": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Индекс приложения"])},
    "app_args": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Аргументы приложения"])},
    "app_accounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Учетные записи приложений"])},
    "app_assets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Иностранные активы приложения"])},
    "boxes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Коробки"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Имя"])},
    "genesis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Идентификатор Genesis"])},
    "genesis_hash": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["genesis_hash"])},
    "session_proposals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Предложения по сеансам"])},
    "toggle_camera": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Переключить камеру"])},
    "init_wc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Инициализировать подключение к Wallet Connect"])},
    "sign_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подписать все"])},
    "proposer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proposer"])},
    "all_accounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use all wallet accounts"])}
  },
  "new_account_wc": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить учетную запись WalletConnect"])},
    "account_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Имя аккаунта"])},
    "last_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Последняя ошибка"])},
    "scan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сканируйте QR-код"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["address"])},
    "save_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сохранить address в кошельке"])},
    "copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скопировать URI в буфер обмена"])},
    "show_qr_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Показать QR-код"])}
  },
  "new_account_2fa": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Учетная запись 2FA"])},
    "help1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Учетная запись 2FA позволяет вам создать учетную запись с мультиподписью с настройкой 3 учетных записей с порогом 2, необходимым для подписи."])},
    "help2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Первая учетная запись может быть защищена паролем, и в случае взлома вашего кошелька хакер не сможет подписывать транзакции от вашего имени. Если ваше устройство 2FA сломается или служба 2FA выйдет из строя, вы можете повторно ввести ключ своей учетной записи. на другую мультиподпись 2FA с вашей первой и второй учетной записью."])},
    "help3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Служба 2FA — это сторонняя служба, которая может быть скомпрометирована. Если хакер получит ваш закрытый ключ и закрытый ключ службы 2FA, он может подписывать транзакции от вашего имени. Используя службу 2FA, вы увеличиваете вероятность того, что хакер кто получает возможность подписывать транзакции с помощью вашей основной или резервной учетной записи, не может подписывать транзакции с вашей учетной записи с мультиподписью."])},
    "2fa_provider": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поставщик 2FA"])},
    "hot_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Горячий аккаунт"])},
    "cold_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Учетная запись холодного хранения"])},
    "request_qr_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Запросить QR-код 2FA"])},
    "scan_qr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пожалуйста, отсканируйте QR-код с помощью приложения 2FA"])},
    "manual_entry_key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Клавиша ручного ввода"])},
    "confirm_2fa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подтвердить настройку"])},
    "confirm_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пожалуйста, напишите код 2FA из приложения авторизации, чтобы подтвердить настройку. После подтверждения невозможно повторно запросить секретный код 2FA."])},
    "2fa_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Код 2FA"])},
    "save_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сохранить address"])}
  },
  "onlineofflinedialog": {
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Защитите сеть с помощью баланса вашего аккаунта"])},
    "warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы можете выбрать узел публичного участия, чтобы проверить блоки в сети. Вы сгенерируете новые ключи участия на общедоступном сервере алгоритмов и подпишете транзакцию, чтобы позволить этим ключам участия собирать данные блоков. Это будет стоить вам как одна транзакция в сети (алгоритм 0,001). Состояние онлайн-аккаунта не означает, что ваш закрытый ключ доступен в Интернете. Это означает, что ваша учетная запись зарегистрирована на узле онлайн-алгода, который защищает блокчейн."])},
    "makeOnline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создать аккаунт онлайн"])},
    "makeOffline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отключить аккаунт"])},
    "host": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Хост Algod"])},
    "fromclipboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Process data from clipboard"])},
    "stakingRegistration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register for staking - 2A network fee"])},
    "stakingHelp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By paying 2 algorand now as network fee, and having more than 30k algos on your account, you are eligible to receive the staking rewards anytime the staking node mints new block."])},
    "onlineRounds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of rounds to protect the network"])}
  },
  "new_account_shamir": {
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Восстановление резервной учетной записи threshold"])},
    "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Резервное копирование Shamir — это метод разделения мнемоники на N частей, где M учетных записей — это порог восстановления."])},
    "мнемоника": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мнемоника"])},
    "add_mnemnic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить мнемонику"])},
    "account_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Имя учетной записи"])},
    "recover": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Восстановить"])}
  },
  "account_export": {
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Экспорт аккаунта"])},
    "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["На этой странице вы можете экспортировать свой закрытый ключ в виде мнемоники Algorand или резервной копии threshold."])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пожалуйста, укажите пароль вашего кошелька"])},
    "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Продолжить"])},
    "algo_mnemonic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Алгорическая мнемоника"])},
    "shamir_backup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Резервная копия Шамира"])},
    "toggle_qr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Переключить QR-код"])},
    "recovery_threshold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Порог восстановления"])},
    "number_of_mnemonics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Количество мнемоник"])},
    "generate_shamir": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сгенерировать мнемонику threshold"])},
    "previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Предыдущий"])},
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Далее"])},
    "index": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Индекс Шамира"])},
    "shamir_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пожалуйста, сохраните мнемонику в очень безопасном месте. Обязательно запишите все части резервной копии threshold и не храните порог восстановления в одном месте."])},
    "algo_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пожалуйста, сохраните мнемонику в очень безопасном месте."])},
    "dev_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Информация о разработчике"])}
  },
  "arc200": {
    "optin_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавьте актив ARC 200 в аккаунт"])},
    "app_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Идентификатор приложения ARC200"])},
    "fetch_action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Получить информацию об активе"])},
    "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сброс"])},
    "examples": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Примеры ресурсов"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Имя"])},
    "symbol": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Символ"])},
    "decimals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Децимальчики"])},
    "supply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Общая поставка"])},
    "balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Баланс счета"])},
    "is_opted_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Аккаунт может получить актив"])},
    "create_box": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создать ящик для этого актива для этой учетной записи"])},
    "save_action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сохранить актив в свою учетную запись"])}
  },
  "scheduled_payments": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scheduled payments management"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["With decentralized scheduler you will deploy the smart contract - escrow account which will be used for purpose to allow any executor perform withdrawal from your escrow account to the destination account when the time comes. Executors are incentivized to execute your scheduled task with vision of receiving small fee paid in ASA.Gold token - 0.001 gram of gold."])},
    "new_scheduled_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New scheduled payment"])},
    "period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Period"])},
    "pay_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pay to"])},
    "send_asset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asset to send"])},
    "create_escrow_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create escrow account"])},
    "list_of_payments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List of active payments"])},
    "no_payment_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No scheduled payments has been found"])},
    "app_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Escrow app id"])},
    "app_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Escrow app address"])},
    "start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start"])},
    "fee_balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fee balance"])},
    "execution_fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Execution fee"])},
    "description_detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In this detail overview of the scheduled payment you can see the current asset balance of the escrow account and you have actions to deposit more assets to the escrow account or to executors."])},
    "details_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scheduled payment details"])},
    "seconds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["seconds"])},
    "deposit_fee_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deposit asa.gold token to fee pool smart contract"])},
    "fee_asset_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Execution fee token"])},
    "amount_to_deposit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount to deposit to fees"])},
    "get_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get more tokenized gold"])},
    "list_of_assets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List of assets at escrow account"])},
    "assets_loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asset information is loading"])},
    "asset_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asset ID"])},
    "asset_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asset name"])},
    "asset_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asset amount"])},
    "deposit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deposit"])},
    "optin_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Escrow account optin action"])},
    "optin_to_asset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opt in to asset"])},
    "optin_click": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opt in"])},
    "withdraw_asset_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Withdraw from escrow account"])},
    "withdraw_asset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asset to withdraw"])},
    "withdraw_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount to withdraw"])},
    "withdraw_click": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Execute withdrawal"])},
    "load_script_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Load app execution profile"])},
    "load_click": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Load"])},
    "xgov_promo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This work has been performed with support from the Algorand Foundation xGov Grants Program"])}
  }
}