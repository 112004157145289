export default {
  "404": {
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fout 404 - Bladsy is nie gevind nie"])},
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die webwerf wat jy soek, kan nie gevind word nie."])}
  },
  "footer": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A-Wallet - Oopbron PWA Algorand gemeenskap beursie"])}
  },
  "login": {
    "new_wallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuwe beursie"])},
    "new_wallet_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beursie naam"])},
    "new_wallet_pass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beursie wagwoord"])},
    "new_wallet_button_create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skep beursie"])},
    "new_wallet_button_import": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voer beursie in"])},
    "new_wallet_help1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jou beursie sal in jou webblaaier gestoor word. Beursie wagwoord is nodig om die beursie te oop en die rekeninge binne die beursie te sien en vir transaksies te teken."])},
    "new_wallet_help2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ons beveel aan dat die wagwoord 12 of meer karakters lank is, met klein letters, hoofletters, syfers en spesiale karakters."])},
    "open_wallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oop beursie"])},
    "select_wallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kies beursie"])},
    "wallet_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beursie wagwoord"])},
    "strength": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sterkte"])},
    "new_wallet_button_open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oop beursie"])},
    "basic_usage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Basiese gebruik"])},
    "tether_usage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hoe om Tether (USDt) te gebruik"])},
    "source_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AWallet bronkode"])}
  },
  "global": {
    "go_back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gaan terug"])},
    "go_home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gaan huis toe"])},
    "copy_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kopieer adres"])},
    "copied_to_clipboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gekopieer na knipbord"])},
    "stop_camera": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stop kamera"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kanselleer"])},
    "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ja"])},
    "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nee"])},
    "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laai.."])},
    "last_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laaste fout"])},
    "keyword_search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sleutelwoord soek"])}
  },
  "navbar": {
    "logo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A Beursie"])},
    "toggle_nav": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skakel navigasie"])},
    "list_accounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lys my rekeninge"])},
    "new_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuwe rekening"])},
    "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instellings"])},
    "asset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bate"])},
    "asset_create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skep NFT"])},
    "faq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["V&A"])},
    "privacy_policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privaatheidsbeleid"])},
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uitlog"])},
    "vote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stem"])},
    "swap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RUIL"])},
    "connect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verbind"])}
  },
  "swap": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ruil bates"])},
    "network": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Netwerk"])},
    "network_not_supported": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Netwerk word nie ondersteun nie"])},
    "has_sk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slegs standaardrekeninge met privaatsleutels word tans ondersteun"])},
    "swap_asset_from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kies bate waarvan jy wil ruil"])},
    "swap_asset_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kies bate waarna jy wil ruil"])},
    "swap_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stel die bedrag in om te ruil"])},
    "get_quote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kry kwotasie"])},
    "apps_optin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SWAP vereis dat jou rekening inskakel by die programme"])},
    "apps_optin_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skakel in by die programme"])},
    "execute_button_deflex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uitvoer met Deflex"])},
    "execute_button_folks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uitvoer met Folks router"])},
    "slippage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slippage persentasie"])}
  },
  "govtoolsmenu": {
    "gen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Genereer rekeninge"])},
    "distribute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Versprei algo 1->N"])},
    "optin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skakel in vir bestuur"])},
    "pay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Massa betaal N->1"])},
    "merge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voeg rekeninge saam N->1"])}
  },
  "merchant": {
    "make_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betaalpoort"])},
    "pay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betaal"])},
    "to_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["na die algorand-adres"])},
    "please": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["asseblief"])},
    "network": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Netwerk"])},
    "matching_symbol": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passende simbool"])},
    "network_fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Netwerkfooie"])},
    "pay_qr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betaal met QR-kode"])},
    "pay_nativewallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betaal met algorand-beursie"])},
    "pay_webwallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betaal met webbeursie"])},
    "cancel_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kanselleer betaling"])},
    "payment_received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betaling ontvang"])},
    "go_back_to_merchant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gaan terug na handelaar"])},
    "waiting_for_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wag vir die betaling"])},
    "error_asset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fout met die ophaling van bate: "])}
  },
  "gateway": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gratis oopbron betaalpoort sonder 'n middelman"])},
    "perex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Begin met die aanvaarding van algo, USDC, USDT of ander bates op jou webwerf. Deur kriptogeld sonder 'n finansiële middelman te aanvaar, sal jy jou verkope op jou webwerf verhoog en jou wins verhoog."])},
    "how": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hoe werk dit?"])},
    "create_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skep 'n unieke passende simbool, bv. bestelling id"])},
    "redirect_to_gateway": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stuur gebruiker na die betaalpoort"])},
    "customer_pays": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kliënt betaal direk aan jou algorand-rekening"])},
    "customer_redirected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kliënt sal na suksesvolle betaling terug na jou webwerf gestuur word"])},
    "use_your_money": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebruik jou geld. Jy kan USDT ruil en USD van die ruil neem, of jy kan met USDT betaal vir ander goedere of dienste"])},
    "security": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is dit veilig?"])},
    "you_make_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jy maak die betalingsbesonderhede. Jy stel die suksesvolle en kanselleer URL-adres op. Ons stel jou in kennis wanneer die betaling gedoen is. Jy moet in jou agterkant kontroleer waar jy die betaling aanvaar het dat dit ontvang is en jy die kliënt in kennis stel."])},
    "opensource": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hierdie projek is oopbron. Jy kan dit kloon, wysig, of in jou eie privaat omgewing vir kommersiële doeleindes uitvoer"])},
    "just_website": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die betaalpoort is net 'n HTML-webwerf met javascript. Geen ander agterkant word gebruik nie, behalwe amptelike algorand-nodes."])},
    "no_restrictions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jy hoef nie 'n AWallet-rekening te hê om die betaalpoort te gebruik nie."])},
    "allowed_parameters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toegelate parameters"])},
    "parameters_perex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jy kan enige van hierdie parameters instel. Die enigste verpligte velde sluit die adres waar jy jou betaling wil ontvang en die transaksiebedrag in. Die betalingsadres bestaan uit twee dele: betalingsinligting, en poortinstellings. Beide velde is base64 gekodeer en geskei deur \"/\". Maak asseblief seker dat jy hulle korrek url-kodeer. Betalingsinligting is die basiese algorand-protokoladres wat byvoorbeeld in die QR-kodes gebruik word."])},
    "payment_info_params": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toegelate parameters"])},
    "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["gebruik die bate basiese eenheid. Byvoorbeeld 1 ALGO = 1000000"])},
    "fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["die bedrag in mikroalgos. Byvoorbeeld 0.001 ALGO = 1000"])},
    "network": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Netwerk vir foutopsporing doeleindes"])},
    "settings_params": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toegelate instellings"])},
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL-adres waarna die kliënt na suksesvolle betaling gestuur word"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL-adres waarna die kliënt na hy die betaling kanselleer, gestuur word"])},
    "example": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voorbeeld"])},
    "turn_on_build_tool": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skakel die bouwerktuig aan"])},
    "turn_off_build_tool": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skakel die bouwerktuig af"])},
    "tether": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tether hoofnet"])},
    "algorand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Algorand"])},
    "asset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jy kan bate-id vir die transaksie spesifiseer"])},
    "error_transaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vul asseblief ten minste rekening, bedrag en bate in"])},
    "error_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL-adres moet met https:// begin"])},
    "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geskep knoppie"])},
    "payment_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betalingsinligting"])},
    "callback_configuration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terugroepkonfigurasie"])},
    "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geskep skakel"])},
    "html_example": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HTML-skakelvoorbeeld"])}
  },
  "receive": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ontvang betaling"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ontvang betaling na adres"])},
    "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedrag"])},
    "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Note"])},
    "decimals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Information about decimal place to QR code"])},
    "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suggested label of the receiver address"])},
    "noteeditable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Note is editable by user"])}
  },
  "acc_overview": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account overview"])},
    "pay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Make new payment"])},
    "receive_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receive payment"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address"])},
    "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount"])},
    "amount_without_pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedrag sonder hangende belonings"])},
    "rewards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belonings"])},
    "pending_rewards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hangende belonings"])},
    "reward_base": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beloningsbasis"])},
    "round": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ronde"])},
    "apps_local_state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apps plaaslike staat"])},
    "apps_total_schema": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apps totale skema"])},
    "assets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bates"])},
    "created_apps": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geskepde apps"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
    "multisignature_threshold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multisignature-drempel"])},
    "multisignature_addresses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multisignature-adresse"])},
    "refresh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verfris"])},
    "transactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaksies"])},
    "no_transactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen transaksies gevind nie"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soort"])},
    "time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tyd"])},
    "tr_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedrag"])},
    "sender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stuurder"])},
    "receiver": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ontvanger"])},
    "receiver_rewards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ontvangerbelonings"])},
    "confirmed_round": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bevestigde ronde"])},
    "fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fooi"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verwyder rekening"])},
    "delete_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verwyder rekening"])},
    "delete_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is jy seker jy wil hierdie rekening van jou beursie verwyder?"])},
    "delete_confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verwyder hierdie rekening"])},
    "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rekening is verwyder"])},
    "asset_optin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opt-in na bate"])},
    "ask_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vra 'n vraag"])},
    "rekey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Herstel rekening"])},
    "rekeyedTo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Herstel na rekening"])},
    "account0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ledger primêre rekening"])},
    "slot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rekening by ledger gleuf"])},
    "connect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WalletConnect"])},
    "payment_gateway": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betaalpoort"])},
    "export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voer uit"])},
    "no_assets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daar is nog geen bates op hierdie rekening nie"])},
    "actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aksies"])},
    "pay_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stuur geld of tokens na ander rekeninge in die netwerk."])},
    "swap_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jy kan een token vir 'n ander ruil deur DEX-aggregator dienste te gebruik"])},
    "rekey_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["As jy bang is dat jou rekening se privaatsleutel dalk gekompromitteer is, kan jy jou rekening na 'n nuwe privaatsleutel herstel en jou oorspronklike adres veilig hou."])},
    "asset_optin_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["As jy 'n bate op die algorand-netwerk wil ontvang, moet jy die rekening vir daardie token oopmaak."])},
    "receive_payment_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jy kan jou klante of vriende vra om jou geld te stuur. Hierdie bladsy sal 'n betalings-QR-kode genereer sodat ander dit met een klik kan doen."])},
    "export_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jy kan shamir-back-up mnemoniese of algorand-mnemoniese vir rekeninge met privaatsleutels genereer."])},
    "arc14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ARC 14-verifikasie"])},
    "arc14_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ARC 14 is 'n verifikasie-standaard wat onderteken transaksies gebruik. Jy kan die verifikasie-token op hierdie bladsy genereer."])},
    "account_actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rekeningaksies"])},
    "asset_optinArc200": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OptIn na ARC200-bate"])},
    "asset_optinArc200_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jy kan op jou rekening die ARC200-bates sien. ARC200-bates is slimkontrakte met 'n funksie wat enigiemand se rekening kan opt-in om die bate te kan ontvang. ARC200-bates kan gevaarlik wees en jy moet 'n betroubare oudit van die slimkontrak vereis sodat jy dit volledig kan vertrou."])},
    "scheduled_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scheduled payment"])},
    "scheduled_payment_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can setup and manage a scheduled periodic payments to another accounts"])},
    "hide_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hide account"])},
    "unhide_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unhide account"])},
    "vote_first_round": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vote First Round"])},
    "vote_last_round": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vote Last Round"])},
    "vote_key_dilution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vote Key Dilution"])},
    "selection_key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selection Key"])},
    "vote_key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vote Key"])},
    "stateproof_key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["StateProof Key"])},
    "realm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Realm"])},
    "arc14msig_process": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please sign ARC14 authentication transaction and return back to this form."])},
    "hasArc14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ARC14 auth has been loaded."])},
    "generating_keys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generating participation keys.. Please be patient, the participation node is performing CPU sensitive task."])},
    "button_sign_keyreg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign key registration tx"])},
    "button_close_custom_keyreg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close KeyReg tx form"])},
    "button_activate_wizzard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activate wizard"])},
    "button_fetch_realm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Step 1/4: Fetch ARC14 realm"])},
    "button_sign_arc14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Step 2/4: Sign ARC14 realm"])},
    "button_sign_arc14_msig": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Step 2/4: Multisign ARC14 realm"])},
    "button_load_participatin_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Step 3/4: Load participation data"])},
    "button_sign_keyreg_tx": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Step 4/4: Sign participation tx"])},
    "button_custom_keyreg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom KeyReg tx"])},
    "making_account_online": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Setting your account to online state. Please wait a while"])},
    "making_account_offline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Setting your account to offline state. Please wait a while"])}
  },
  "acc_overview_assets": {
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naam"])},
    "search_by_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soek op naam"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soort"])},
    "search_by_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soek op bate-tipe"])},
    "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Id"])},
    "search_by_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soek op bate-id"])},
    "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedrag"])},
    "search_by_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soek op bedrag"])},
    "actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aksies"])}
  },
  "optin": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aanvaar oordragte van bate in rekening"])},
    "searchById": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ek weet die bate-ID"])},
    "assetId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bate-ID"])},
    "assetName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bate Naam"])},
    "searchButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vind bate"])},
    "assetInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inligting oor die bate"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Totaal"])},
    "unit_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eenheidsnaam"])},
    "decimals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desimale"])},
    "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL"])},
    "metadata_hash": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metadatabeek"])},
    "default_frozen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standaard bevrore"])},
    "creator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skepper adres"])},
    "manager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestuurder adres"])},
    "clawback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terugtrek adres"])},
    "freeze": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bevries adres"])},
    "reserve": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reserwe adres"])},
    "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aksie"])},
    "optin_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opt in"])},
    "dialog_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opt in na bate"])},
    "optin_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is jy seker jy wil 0.001 algo betaal om in te skakel na hierdie bate? Nadat jy ingeskakel het, sal jy hierdie bate in jou rekening kan ontvang."])},
    "optin_confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ek wil in skakel"])},
    "asset_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bate nie gevind nie"])},
    "asset_opt_in_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suksesvol ingeskakel vir die bate"])}
  },
  "accounts": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rekeninge oorsig"])},
    "no_accounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen rekeninge gevind nie"])},
    "account_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rekening naam"])},
    "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedrag"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adres"])},
    "pay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betaal"])},
    "connect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WalletConnect"])},
    "show_on_netowork_accounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wys rekeninge vir netwerk"])},
    "2fa_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2FA-kode"])}
  },
  "newacc": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New account"])},
    "create_basic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create basic account"])},
    "import_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Import account"])},
    "create_multisign_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create multisign account"])},
    "watch_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Watch account"])},
    "write_mnemonic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Write down 25 word mnemonic phrase"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Internal account name"])},
    "create_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create account"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adres"])},
    "multisig_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multisignature-rekening kan transaksies alleen verwerk as N-rekeninge wat by die aanmaak van die rekening gelys is, die transaksie onderteken."])},
    "select_account_from_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kies bestaande rekeninge in jou beursie"])},
    "add_other_accounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voeg jou vriende se rekeninge by - een rekening per lyn"])},
    "trashold_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kies hoeveel rekeninge nodig is om die transaksie te onderteken"])},
    "create_account_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Om die rekening te skep, moet jy seker maak dat jy die mnemoniese frase back-up. Is dit veilig om die frase nou te wys?"])},
    "show_mnemonic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wys mnemoniese"])},
    "position_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wat is die woord op posisie n."])},
    "mnemonic_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skryf die mnemoniese woorde in die vertoonde volgorde neer, kopieer of neem 'n foto. Die volgende stap sal wees om sommige van die woorde van hierdie frase te bevestig en ons sal dit in die versleutelde stoor in jou webblaaier stoor. Dit is veilig om hierdie mnemoniese te stoor en hierdie adres te gebruik sonder om dit na die rekening te stoor. Maak seker dat jy nooit die mnemoniese frase verloor nie."])},
    "start_challenge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Begin die uitdaging"])},
    "create_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skep nuwe"])},
    "hide_mnemonic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verberg mnemoniese"])},
    "drop_phrase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laat val frase"])},
    "scan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skandeer van QR-kode"])},
    "create_vanity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skep vaniteitsrekening"])},
    "vanity_start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adres sal begin met"])},
    "vanity_mid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adres sal bevat"])},
    "vanity_end": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adres sal eindig met"])},
    "vanity_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aantal adresse verwerk"])},
    "vanity_button_start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vind die adres"])},
    "vanity_button_stop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stop soek"])},
    "vanity_workers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aantal drade om te gebruik"])},
    "vanity_use": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebruik hierdie rekening"])},
    "auto_lock_off": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die outomatiese slotfunksie is afgeskakel"])},
    "ledger_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HW Beursie - Ledger Rekening"])},
    "wc_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wallet Connect Rekening"])},
    "twofa_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2FA Rekening"])},
    "show_qr_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wys QR-kode"])},
    "email_pass_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-pos & Wagwoord rekening"])},
    "shamir_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shamir-back-upherstel"])},
    "overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuwe rekening aksies"])},
    "create_basic_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Algorand basiese rekening is ED25519 rekening met privaatsleutel. Hierdie metode gebruik jou rekenaar se ewekansige getalgenerator om 'n geheim vir jou rekening te skep."])},
    "ledger_account_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Een van die mees veilige oplossings is om die geheime sleutelgenerering deur 'n gespesialiseerde toestel te hanteer. Hierdie rekening maak gebruik van die Ledger-toestel om jou rekening te skep en die ondertekeningsoperasies te hanteer."])},
    "wc_account_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jy kan met jou vriend se beursie verbind deur die wallet connect-rekening tipe te gebruik. Jy kan hulle versoek om die boodskap byvoorbeeld as deel van multisig te onderteken."])},
    "twofa_account_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Twee faktor rekening is 'n multisig rekening, waar een rekening die rekening van jou keuse is, byvoorbeeld ledger rekening of e-pos wagwoord rekening, tweede rekening is herstelrekening - ideaal rekening wat nie in dieselfde veilige berging is nie, en derde rekening is die twee faktor rekening van die bediener wat transaksies onderteken wanneer jy die korrekte twee faktor pin kode voorsien."])},
    "email_pass_account_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ARC76 E-pos & Wagwoord rekening stel jou in staat om algorand rekening vanaf enige teks te genereer. Die teks word gehash en genereer 32 byte as saad vir jou algorand rekening. Dit kan 'n alternatief wees vir ander tipes ewekansige getalgenerators in jou rekenaar of hw-toestel"])},
    "create_vanity_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can brute force nice account address for your ED25519 account. This method simply tries a lot of combinations of creating account and lets you pick first or last letters of the account address."])},
    "create_multisign_account_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multisig accounts are the most secure accounts. If used in combination of multiple ledger devices and different random number generators, this provides enterprise level of security which is hardcoded directly to the algorand blockchain."])},
    "watch_account_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can track any account on the network."])},
    "import_account_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can recover the account from the algorand recovery mnemonic."])},
    "shamir_account_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can recover the account from the shamir backup recovery mnemonics."])},
    "create_first": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create your first account"])},
    "no_accounts_at_network": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are no accounts for selected network"])},
    "basic_options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Basic options"])},
    "advanced_options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Advanced options"])},
    "skip_challange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skip challange"])},
    "new_account_challange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New account challange"])},
    "click_to_show_positions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click to show positions"])},
    "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["to"])}
  },
  "arc76account": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email and password account (ARC-0076)"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hash of combination of your email and your password will generate seed bytes for your first algorand account. You can change the password or email only by rekeying to another account."])},
    "description2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ARC-0076 standard will be used with algorithm PBKDF2-999999-SHA256. We recommend the password length to be at least 50 characters long. This type of account may be served as secondary type of random number generator and may strengthen your security. Please keep the secret secure and do not reuse it."])},
    "arc_draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ARC-0076 is in the DRAFT state. The specification can change. Please consider this implementation for experimental usage."])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "select_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select very very very secure password."])},
    "save_password_switch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save password"])},
    "password_not_stored": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Watch out. We do not store your password. We will store only the account public address and email to the secure storage. When you will be doing transactions with this account you will be prompted to enter the password."])},
    "gui_not_implemented": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GUI for signing transaction without stored password is not currently implemented."])}
  },
  "import": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Import wallet"])},
    "wallet_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wallet name"])},
    "wallet_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wallet file"])},
    "import_wallet_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Import wallet"])},
    "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can import previously exported wallets. Wallet password stays within the exported file, so you can access it after the import with the same password as before. You can change the password after login from the settings page."])}
  },
  "acc_type": {
    "basic_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Basic account"])},
    "multisig_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multisignature account"])},
    "public_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Public account"])},
    "rekeyed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rekeyed account"])},
    "ledger_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HW ledger account"])},
    "wc_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WalletConnect account"])}
  },
  "account": {
    "select_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select account"])}
  },
  "pay": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Make payment - from"])},
    "toggle_camera": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toggle camera"])},
    "note_is_b64": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Note is Base64 encoded. If enabled you can post base64 input in the note field and send it as raw data to the network."])},
    "selected_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selected account"])},
    "multisig_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multisignature account"])},
    "create_proposal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create proposal"])},
    "sign_proposal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign & send proposal"])},
    "signature_from_friend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter signature from your friend here"])},
    "load_multisig_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Load multisignature data"])},
    "pay_to_wallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pay to wallet account"])},
    "pay_to_other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pay to other account"])},
    "store_other_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It is better to store the account to which you are sending money in the address book"])},
    "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount"])},
    "fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fee"])},
    "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Note"])},
    "review_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Review payment"])},
    "review_payment_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please review your payment"])},
    "from_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["From account"])},
    "pay_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pay to account"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
    "transaction_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaction details"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "first_round": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First Round"])},
    "last_round": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last Round"])},
    "genesis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Genesis ID"])},
    "tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tag"])},
    "to_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To"])},
    "signatures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Signatures"])},
    "signed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Signed"])},
    "not_signed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not signed"])},
    "sign_with": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign with"])},
    "sign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign"])},
    "send_to_other_signators": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send this data to other signators"])},
    "send_to_network": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send to the network"])},
    "state_sending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sending payment to the network"])},
    "state_sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment sent to the network. Tx: "])},
    "state_waiting_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waiting for network confirmation."])},
    "state_confirmed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmation has been received. Your payment is in the block"])},
    "transaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaction"])},
    "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error"])},
    "asset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asset"])},
    "state_error_not_sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment has probably not reached the network. Are you offline? Please check you account"])},
    "set_max": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set maximum"])},
    "environment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Environment"])},
    "pay_to_address_malformed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pay To address seems to be malformed."])},
    "asset_failed_to_load": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It seems that desired asset has failed to load"])},
    "asset_too_small_balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It seems your balance is below desired pay amount"])},
    "rekey_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Herlei na rekening"])},
    "rekey_to_wallet_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Herlei na beursie rekening"])},
    "rekey_to_external_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Herlei na eksterne rekening"])},
    "rekey_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Herlei rekening"])},
    "rekey_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gee asseblief spesiale aandag aan hierdie transaksie. Van nou af sal die rekening 'n ander privaatsleutel gebruik om transaksies te voltooi. As jy nie die privaatsleutel van die nuwe rekening het nie, sal jy waarskynlik die vermoë om transaksies te onderteken, verloor."])},
    "combine_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kombineer handtekeninge van ander ondertekenaar"])},
    "combine_action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kombineer handtekeninge"])},
    "return_to_wc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terug na WalletConnect"])},
    "select_account_for_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kies rekening waarvan jy die betaling wil maak"])},
    "create_multisig_proposal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skep multisig voorslag"])},
    "2fa_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2FA-kode"])},
    "sign_arc14_request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Onderteken toestemming versoek"])},
    "toggle_send_to_others_form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wissel stuur na ander vorm"])},
    "toggle_combine_with_others_form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wissel kombineer met ander vorm"])},
    "process_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verwerk betaling"])},
    "tx_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaksie tipe"])}
  },
  "settings": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settings"])},
    "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Server"])},
    "environment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Environment"])},
    "mainnet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mainnet"])},
    "testnet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Testnet"])},
    "devnet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reach Devnet"])},
    "sandbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sandbox"])},
    "host": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["host"])},
    "token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["token"])},
    "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Language"])},
    "pass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wallet password"])},
    "oldpass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Old password"])},
    "newpass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New password"])},
    "repeatpass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm new password"])},
    "backup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Backup wallet"])},
    "backup_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can backup your wallet and import to another computer."])},
    "create_backup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create backup"])},
    "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete wallet"])},
    "strength": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Strength"])},
    "updated_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password has been updated"])},
    "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom server settings"])},
    "protocol_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register protocol handler"])},
    "protocol_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register web+algorand:// handler"])},
    "protocol_change_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successfully updated the protocol handler"])},
    "dev_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show information for developers"])},
    "update_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update password"])}
  },
  "transaction": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaction detail"])},
    "tr_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaction ID"])},
    "tr_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaction type"])},
    "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Note"])},
    "note_raw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Raw data"])},
    "note_decoded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Decoded data"])},
    "tr_close_rewards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close rewards"])},
    "closing_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Closing amount"])},
    "confirmed_round": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmed round"])},
    "fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fee"])},
    "first_valid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First valid"])},
    "genesis_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Genesis ID"])},
    "genesis_hash": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Genesis hash"])},
    "intra_round": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intra round offset"])},
    "last_valid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last valid"])},
    "payment_tr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment transaction"])},
    "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount"])},
    "close_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close amount"])},
    "receiver": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receiver"])},
    "receiver_rewards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receiver rewards"])},
    "round_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Round time"])},
    "sender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sender"])},
    "sender_rewards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sender rewards"])},
    "signature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Signature"])},
    "created_asset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created asset"])},
    "asset_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asset name"])},
    "asset_creator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asset creator"])},
    "asset_manager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asset manager"])},
    "asset_reserve": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asset reserve"])},
    "asset_freeze": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asset freeze"])},
    "asset_clawback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asset clawback"])},
    "asset_unitName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asset unit name"])},
    "asset_total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asset total"])},
    "asset_decimals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asset decimals"])},
    "asset_defaultfrozen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asset default frozen"])},
    "asset_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asset url"])},
    "asset_metadata": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asset metadata"])},
    "time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Time"])},
    "group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Group ID"])}
  },
  "privacy": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy policy"])},
    "q1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What private information about me do you collect?"])},
    "a1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["None. Besides IP address and browser which is stored in logs for every internet service, we do not collect any form of private information such as your name, email or address."])},
    "q2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What do you store in cookies?"])},
    "a2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nothing. We do not use cookies. We use persistent local storage called IndexedDB for the storage of your wallet."])},
    "q3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How do you collect analytics information?"])},
    "a3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We do not. We do not use any form of analytics service, e.g. google analytics, so that you can feel safer. The website is purely HTML with javascript. We do know your IP address only when you make requests to the server. Depending on your settings and your algod server or indexer, the selected server also may know your IP address."])},
    "q4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Does use of this service affect the ads I see?"])},
    "a4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No. Ad servers do not have any information from us about any usage. The only way how, e.g. google, may know you are using this service is if you access this site from google search."])},
    "q5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Who provides this service?"])},
    "a5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scholtz & Company, jsa has created this service. Company Id: 51 882 272, VAT ID: SK2120828105, based in Bratislava, Slovakia.  If this is running in the official website a-wallet.net, the company is also the service provider. The company is not liable for any service, nor any errors in the application."])}
  },
  "assetcreate": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New NFT / ASA"])},
    "show_advanced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show advanced form"])},
    "assetName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Friendly name"])},
    "creator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creator address"])},
    "hide_advanced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hide advanced form"])},
    "creator_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create address or import address first"])},
    "default_fronzen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frozen: User accounts will need to be unfrozen before transacting"])},
    "decimals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Decimals: integer number of decimals for asset unit calculation"])},
    "totalIssuance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total number of this asset available for circulation"])},
    "unitName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Used to display asset units to user"])},
    "assetURL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optional URL relating to the asset"])},
    "assetMetadataHash": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optional hash commitment of some sort relating to the asset. Base64 format"])},
    "manager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manager can modify manager, reserve, freeze, and clawback account for the asset."])},
    "reserve": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reserve address: Specified address is considered the asset reserve (it has no special privileges, this is only informational)"])},
    "freeze": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Freeze address: Specified address can freeze or unfreeze user asset holdings"])},
    "clawback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clawback address: Specified address can revoke user asset holdings and send them to other addresses"])},
    "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Note set in the create transaction"])},
    "create_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create NFT / ASA"])}
  },
  "new_account_ledger": {
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Ledger account"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ledger is a physical device - HW wallet which can store your private key. The maximum security for storing the assets on algorand is multisig account with all signators secured by ledger devices."])},
    "last_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last error"])},
    "connect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connect ledger and load algorand address"])},
    "slot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slot"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address"])},
    "primary_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Primary address"])},
    "account_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account name"])},
    "save_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save the address to the wallet"])}
  },
  "faq": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frequently asked questions"])},
    "q1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About AWallet"])},
    "a1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Algo wallet was initially created by Scholtz & Company, jsa. Open source, HTML only, corporate friendly, and security are the main advantages for its usage. Algorand logo, the words \"algo\" and \"algorand\" are trademarked by Algorand, Inc., and are used with algorand media guidelines https://www.algorand.com/about/media-kit/ ."])},
    "q2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How is my wallet data secured?"])},
    "a2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your data is in the browser only. It is stored in persistent IndexedDB. Every wallet is encrypted using aes256 with your password. If you make a strong enough password, your wallet will never be brute-forced. Your password does not leave your computer and resides only in the browser cache. The cache is destroyed in 5 minutes after login."])},
    "q3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Does my wallet password go to the internet?"])},
    "a3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Never"])},
    "q4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How can I change my wallet password?"])},
    "a4_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login and access your wallet."])},
    "a4_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Navigate to settings."])},
    "a4_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type old password, new password, and confirm new password."])},
    "a4_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit form and your password will be changed."])},
    "q5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Can I move my wallet to another computer?"])},
    "a5_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login and access your wallet."])},
    "a5_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Navigate to settings."])},
    "a5_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go to the backup section and save your data to a local file."])},
    "a5_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In the other computer go to new wallet, import wallet, and upload the downloaded file to the other browser storage."])},
    "a5_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Access the wallet with the same password as before. You can change the password in settings."])},
    "q6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Can I view the source code?"])},
    "a6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes. https://github.com/scholtz/wallet/ This wallet was made open source and is free to be used, copied or modified for any reason, including for commercial purposes. If you are able to participate in the project, please be so kind and create a pull request with your improvement."])},
    "q7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How can I help the project?"])},
    "a7_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donatation to algorand address AWALLETCPHQPJGCZ6AHLIFPHWBHUEHQ7VBYJVVGQRRY4MEIGWUBKCQYP4Y will help the project keep going. The project has not been funded from Algorand Inc. nor the Algorand Foundation."])},
    "a7_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To translate the project or help with the translation, please go to  https://github.com/scholtz/wallet/tree/master/src/locales, copy the translation file to your language and make a pull request."])},
    "a7_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you are a programmer, please do a code review, or help us in any way you think this project should be better."])},
    "q8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How can I create a multisignature account?"])},
    "a8_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First create an account with private keys or create a public account. It is better to give them names that will help you will remember their purpose."])},
    "a8_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go to create a new address and select multisignature address."])},
    "a8_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select all accounts that can sign the transactions and select how many signatures that adhere to the minimum for the network to accept the multisig payment."])},
    "a8_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["After you create the multisignature account, you can access it from the accounts list page."])}
  },
  "connect": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connect to DApps using WalletConnect"])},
    "uri": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WalletConnect URI"])},
    "connect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connect"])},
    "disconnect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disconnect"])},
    "sendBack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send back to DApp"])},
    "reject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reject"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address"])},
    "or": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["or"])},
    "authaddr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authorized Address"])},
    "index": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Index"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
    "from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["From"])},
    "asset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asset"])},
    "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount"])},
    "rekeyto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rekey To"])},
    "method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Method"])},
    "client_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Client ID"])},
    "request_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request ID"])},
    "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove"])},
    "peer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peer"])},
    "connected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connected"])},
    "sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sessions"])},
    "requests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Requests"])},
    "fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fee"])},
    "total_fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Fee"])},
    "sign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign"])},
    "clipboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connect from clipboard"])},
    "sign_txs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please sign individual transactions"])},
    "signed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Signed"])},
    "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To"])},
    "validity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validity"])},
    "rounds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["rounds"])},
    "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Note"])},
    "group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Group"])},
    "app": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["App Index"])},
    "app_args": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["App Args"])},
    "app_accounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["App Accounts"])},
    "app_assets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["App Foreign Assets"])},
    "boxes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Boxes"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "genesis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Genesis ID"])},
    "genesis_hash": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Genesis Hash"])},
    "session_proposals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Session proposals"])},
    "toggle_camera": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toggle camera"])},
    "init_wc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Initialize connection to Wallet Connect"])},
    "sign_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign all"])},
    "proposer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proposer"])},
    "all_accounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use all wallet accounts"])}
  },
  "new_account_wc": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add WalletConnect account"])},
    "account_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account name"])},
    "last_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last error"])},
    "scan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scan the QR code"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address"])},
    "save_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save the address to the wallet"])},
    "copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy URI to clipboard"])},
    "show_qr_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show QR code"])}
  },
  "new_account_2fa": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2FA Account"])},
    "help1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2FA account allows you to create multisig account with 3 accounts setup with threshold of 2 required for signature."])},
    "help2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First account may be password protected account where in the case of the hack of your wallet the hacker cannot sign the transactions on your behalf. If your 2FA device gets broken, or 2FA service gets down, you can rekey your account to another 2FA multisig with your first and second account."])},
    "help3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2FA service is third party service which might be compromised. If hacker receives your private key and receives the private key of the 2fa service, he may sign transactions on your behalf. By using the 2fa service you increase chance that hacker who receive ability to sign transactions with your primary or recovery account is not able to sign transactions from your multisig account."])},
    "2fa_provider": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2FA provider"])},
    "hot_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hot account"])},
    "cold_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cold storage account"])},
    "request_qr_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request 2FA QR code"])},
    "scan_qr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please scan the QR code with your 2FA app"])},
    "manual_entry_key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manual entry key"])},
    "confirm_2fa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm setup"])},
    "confirm_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please write 2FA code from your auth app to confirm the setup. After confirmation it is not possible to request the 2fa secret code again."])},
    "2fa_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2FA code"])},
    "save_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save address"])}
  },
  "onlineofflinedialog": {
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Protect the network with your account's balance"])},
    "warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can select public participation node in order to verify the blocks in the network. You will generate new participation keys at the public algod server and sign the transaction to allow these participation keys collect blocks data. This will cost you as one transaction on the network (0.001 algo). Online account state does not mean that your private key is exposed to the internet. It means that your account is registered at the online algod node which is protecting blockchain."])},
    "makeOnline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Make account online"])},
    "makeOffline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Make account offline"])},
    "host": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Algod host"])},
    "fromclipboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Process data from clipboard"])},
    "stakingRegistration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register for staking - 2A network fee"])},
    "stakingHelp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By paying 2 algorand now as network fee, and having more than 30k algos on your account, you are eligible to receive the staking rewards anytime the staking node mints new block."])},
    "onlineRounds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of rounds to protect the network"])}
  },
  "new_account_shamir": {
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shamir backup account recovery"])},
    "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shamir backup is the method to split mnemonic into N parts where M accounts is the recovery threshold."])},
    "mnemonic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mnemonic"])},
    "add_mnemnic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add mnemonic"])},
    "account_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name of the account"])},
    "recover": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recover"])}
  },
  "account_export": {
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Export account"])},
    "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["On this page you can export your private key in form of algorand's mnemonic or shamir backup."])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please provide your wallet password"])},
    "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue"])},
    "algo_mnemonic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Algorand mnemonic"])},
    "shamir_backup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shamir backup"])},
    "toggle_qr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toggle QR code"])},
    "recovery_threshold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recovery threshold"])},
    "number_of_mnemonics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of mnemonics"])},
    "generate_shamir": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generate shamir mnemonics"])},
    "previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Previous"])},
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next"])},
    "index": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shamir Index"])},
    "shamir_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please secure mnemonic at very secure place. Make sure you write down all parts of shamir backup and do not store the recovery threshold at one place."])},
    "algo_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please secure mnemonic at very secure place."])},
    "dev_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Developer info"])}
  },
  "arc200": {
    "optin_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add ARC 200 asset to account"])},
    "app_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ARC200 App ID"])},
    "fetch_action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fetch asset information"])},
    "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset"])},
    "examples": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Example assets"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "symbol": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Symbol"])},
    "decimals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Decimals"])},
    "supply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total supply"])},
    "balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account balance"])},
    "is_opted_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account can receive asset"])},
    "create_box": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create box for this asset for this account"])},
    "save_action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save asset to your account"])}
  },
  "scheduled_payments": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scheduled payments management"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["With decentralized scheduler you will deploy the smart contract - escrow account which will be used for purpose to allow any executor perform withdrawal from your escrow account to the destination account when the time comes. Executors are incentivized to execute your scheduled task with vision of receiving small fee paid in ASA.Gold token - 0.001 gram of gold."])},
    "new_scheduled_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New scheduled payment"])},
    "period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Period"])},
    "pay_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pay to"])},
    "send_asset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asset to send"])},
    "create_escrow_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create escrow account"])},
    "list_of_payments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List of active payments"])},
    "no_payment_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No scheduled payments has been found"])},
    "app_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Escrow app id"])},
    "app_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Escrow app address"])},
    "start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start"])},
    "fee_balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fee balance"])},
    "execution_fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Execution fee"])},
    "description_detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In this detail overview of the scheduled payment you can see the current asset balance of the escrow account and you have actions to deposit more assets to the escrow account or to executors."])},
    "details_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scheduled payment details"])},
    "seconds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["seconds"])},
    "deposit_fee_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deposit asa.gold token to fee pool smart contract"])},
    "fee_asset_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Execution fee token"])},
    "amount_to_deposit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount to deposit to fees"])},
    "get_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get more tokenized gold"])},
    "list_of_assets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List of assets at escrow account"])},
    "assets_loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asset information is loading"])},
    "asset_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asset ID"])},
    "asset_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asset name"])},
    "asset_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asset amount"])},
    "deposit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deposit"])},
    "optin_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Escrow account optin action"])},
    "optin_to_asset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opt in to asset"])},
    "optin_click": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opt in"])},
    "withdraw_asset_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Withdraw from escrow account"])},
    "withdraw_asset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asset to withdraw"])},
    "withdraw_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount to withdraw"])},
    "withdraw_click": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Execute withdrawal"])},
    "load_script_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Load app execution profile"])},
    "load_click": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Load"])},
    "xgov_promo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This work has been performed with support from the Algorand Foundation xGov Grants Program"])}
  }
}