export default {
  "404": {
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["404-es hiba - az oldal nem található"])},
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A keresett webhely nem található."])}
  },
  "footer": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Algo pénztárca"])}
  },
  "login": {
    "new_wallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Új pénztárca"])},
    "new_wallet_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pénztárca név"])},
    "new_wallet_pass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pénztárca jelszó"])},
    "new_wallet_button_create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pénztárca létrehozása"])},
    "new_wallet_button_import": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pénztárca importálása"])},
    "new_wallet_help1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A pénztárca a böngészőn belül fog tárolódni. A pénztárca megnyitásához, a pénztárcán belüli számlák kijelzéséhez és a tranzakciók aláírásához egy jelszóra van szükség."])},
    "new_wallet_help2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Azt tanácsoljuk, hogy 12 karakternél hosszabb jelszót használj. A jelszóban legyenek kisbetűk, nagybetűk és különleges karakterek."])},
    "open_wallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pénztárca megnyitás"])},
    "select_wallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pénztárca választás"])},
    "wallet_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pénztárca jelszó"])},
    "strength": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erősség"])},
    "new_wallet_button_open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pénztárca megnyitása"])},
    "basic_usage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alapműveletek"])},
    "tether_usage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hogyan használjuk a Tether-t (USDt)"])},
    "source_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AWallet forráskód"])}
  },
  "global": {
    "go_back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vissza"])},
    "go_home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ugrás a kezdőlapra"])},
    "copy_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cím másolása"])},
    "copied_to_clipboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Átmásolva a vágólapra"])},
    "stop_camera": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fényképezőgép leállítása"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mégsem"])},
    "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Igen"])},
    "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nem"])},
    "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betöltés alatt..."])},
    "keyword_search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kulcsszó keresés"])}
  },
  "navbar": {
    "logo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AWallet"])},
    "toggle_nav": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Váltás a navigációban"])},
    "list_accounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Számlák listázása"])},
    "new_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Új számla"])},
    "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beállítások"])},
    "asset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eszköz"])},
    "asset_create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NTF létrehozás"])},
    "faq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GYIK"])},
    "privacy_policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adatvédelem"])},
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kijelentkezés"])},
    "vote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szavazás"])},
    "swap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Csere (SWAP)"])},
    "connect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WalletConnect"])}
  },
  "swap": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pénzeszköz csere"])},
    "network": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hálózat"])},
    "network_not_supported": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A hálózat nincs támogatva"])},
    "has_sk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jelenleg csak a privát kulcsokkal rendelkező szabványos számlák vannak támogatva"])},
    "swap_asset_from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Válaszd ki az elcseréledő pénzeszközt"])},
    "swap_asset_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Válaszd ki azt a pénzeszközt, melyre cserélni szeretnéd"])},
    "swap_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Állítsd be a csere összegét"])},
    "get_quote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kérj ajánlatot"])},
    "apps_optin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A cseréhez be kell nevezned az alkalmazásokba"])},
    "apps_optin_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benevezés az alkalmazásokba"])},
    "execute_button_deflex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Végrehajtás a Deflex használatával"])},
    "execute_button_folks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Végrehajtás a Folks router használatával"])},
    "slippage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Százalékos különbözet"])}
  },
  "govtoolsmenu": {
    "gen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Számlák előállítása"])},
    "distribute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Algo szétosztás 1->N"])},
    "optin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benevezés az önkormányzatba"])},
    "pay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tömeges fizetés N->1"])},
    "merge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Számlák összevonása N->1"])}
  },
  "merchant": {
    "make_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fizetési gateway"])},
    "pay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fizess"])},
    "to_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["az Algorand címnek"])},
    "please": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kérlek"])},
    "network": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hálózat"])},
    "matching_symbol": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Egyedi szimbólum"])},
    "network_fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hálózati díj"])},
    "pay_qr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fizetés QR-kód használatával"])},
    "pay_nativewallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fizetés Algorand pénztárca használatával"])},
    "pay_webwallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fizetés web-es pénztárca használatával"])},
    "cancel_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fizetés törölve"])},
    "payment_received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A küldött összeg megérkezett"])},
    "go_back_to_merchant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vissza a kereskedőhöz"])},
    "waiting_for_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Várakozás a fizetségre"])},
    "error_asset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hiba történt a pénzeszköz betöltésekor: "])}
  },
  "gateway": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szabadon használható, nyílt forráskódú, közvetítők nélküli fizetési gateway"])},
    "perex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fogadj el a webhelyeden Algót, USDC-t, USDT-t vagy más egyéb pénzeszközt. A kriptovaluták pénzügyi közvetítő nélküli elfogadása növeli a webhely eladásait és a hasznot."])},
    "how": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hogyan működik?"])},
    "create_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hozz létre egy egyedi azonosító szimbólumot, pl. a megrendelés azonosítót"])},
    "redirect_to_gateway": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Irányítsd át a felhasználót a gateway-re"])},
    "customer_pays": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A felhasználó közvetlenül a te Algorand számládra fizet"])},
    "customer_redirected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A felhasználó a sikeres fizetési tranzakció után vissza lesz irányítva a webhelyedre"])},
    "use_your_money": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Használd a pénzedet. Az USDT-t a pénzváltókban USD-re válthatod, vagy az UDST-vel fizetni tudsz más árukért vagy szolgáltatásokért."])},
    "security": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mennyire biztonságos mindez?"])},
    "you_make_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Te határozod meg a fizetés részleteit. Te állítod be a sikeres és a törölt fizetéshez tartozó URL címeket. Értesítést kapsz, ha a fizetés megtörtént. Azon a háttérfelületen, ahol a fizetést elfogadod, ellenőrizned kell, hogy megjött-e a pénz, és értesítened kell a vevőt."])},
    "opensource": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ez egy nyílt forráskódú projekt. Klónozható, módosítható, vagy üzleti céllal a saját környezetedben futtatható."])},
    "just_website": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A fizetési gateway egy HTML webhely, amely JavaScript-et futtat. A hivatalos Algorand csomópontokon kívül semmilyen más hátteret nem használ."])},
    "no_restrictions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A gateway használatához nem szükséges AWallet számlával rendelkezned."])},
    "allowed_parameters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Megengedett paraméterek"])},
    "parameters_perex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A paraméterek bármelyike állítható. Kötelezően megadandó mezők: az a cím, ahol fogadni szeretnéd a fizetséget, valamint a tranzakció összege. A fizetési cím két részből áll: a fizetési adatokból és a gateway beállításokból. Mindkét mező base64 kódolású, és egy / jel választja el őket egymástól. Vigyázz rá, hogy mindkettőt megfelelően kódold. A fizetési adat nem más, mint az az Algorand protokoll cím, amelyet pl. a QR-kódokban használnak."])},
    "payment_info_params": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Megengedett paraméterek"])},
    "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["a pénzeszköz alapegységének a használata. Pl. 1 Algo = 1000000"])},
    "fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["az összeg mikroalgókban. Pl. 0,001 Algo = 1000"])},
    "network": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belövéshez használt hálózat"])},
    "settings_params": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Megengedett beállítások"])},
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL cím, ahová a kliens a sikeres fizetés után lesz átirányítva"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL cím, ahová a kliens a 'fizetés törlése' választása után lesz átirányítva"])},
    "example": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Példa"])},
    "turn_on_build_tool": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A build eszköz bekapcsolása"])},
    "turn_off_build_tool": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A build eszköz kikapcsolása"])},
    "tether": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tether mainnet"])},
    "algorand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Algorand"])},
    "asset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Megadható a tranzakcióhoz a pénzeszköz azon."])},
    "error_transaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Töltsd ki a számlát, az összeget és a pénzeszközt"])},
    "error_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az URL címnek a https:// protokollal kell kezdődnie"])},
    "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generált nyomógomb"])},
    "payment_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fizetési információ"])},
    "callback_configuration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Callback konfiguráció"])},
    "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generált hiperlink"])},
    "html_example": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HTML hiperlink példa"])}
  },
  "receive": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fizetés fogadása"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fizetés fogadása ezen a címen"])},
    "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Összeg"])},
    "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Megjegyzés"])},
    "decimals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tizedesjegyek száma a QR-kód számára"])},
    "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A fogadó fél címéhez tartozó címke"])},
    "noteeditable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A megjegyzés mezőt a felhasználó szerkesztheti"])}
  },
  "acc_overview": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Számla áttekintés"])},
    "pay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Új kifizetés létrehozása"])},
    "receive_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fizetés fogadása"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Név"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cím"])},
    "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Összeg"])},
    "amount_without_pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Összeg a függő jutalmak nélkül"])},
    "rewards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jutalmak"])},
    "pending_rewards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Függő jutalmak"])},
    "reward_base": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A jutalom alapja"])},
    "round": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menet"])},
    "apps_local_state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["App-ok lokális állapota"])},
    "apps_total_schema": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["App-ok teljes sémája"])},
    "assets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eszközök"])},
    "created_apps": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Létrehozott app-ok"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Státusz"])},
    "multisignature_threshold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multisig aláírási küszöb"])},
    "multisignature_addresses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multisig címek"])},
    "refresh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frissítés"])},
    "transactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tranzakciók"])},
    "no_transactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nincs egyetlen tranzakció sem"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Típus"])},
    "time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Idő"])},
    "tr_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Összeg"])},
    "sender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Küldő"])},
    "receiver": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fogadó"])},
    "receiver_rewards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A fogadó jutalma"])},
    "confirmed_round": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Megerősített menetek száma"])},
    "fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Díj"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Számla törlése"])},
    "delete_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Számla törlése"])},
    "delete_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Biztosan törölni szeretnéd ezt a számlát a pénztárcából?"])},
    "delete_confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ennek a számlának a törlése"])},
    "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A számla törölve lett"])},
    "asset_optin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pénzeszköz engedélyezése"])},
    "ask_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kérdésem van"])},
    "rekey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Számla átkulcsolás"])},
    "rekeyedTo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Átkulcsolva a köv. számlához"])},
    "account0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ledger elsődleges számla"])},
    "slot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A Ledger rekeszhez (slot) tartozó számla"])},
    "connect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WalletConnect"])},
    "payment_gateway": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fizetési gateway"])},
    "export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exportálás"])},
    "no_assets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Még nincsenek pénzeszközök a számlán"])},
    "actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Műveletek"])},
    "pay_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pénz vagy érmék küldése a hálózat egy másik számlájára."])},
    "swap_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az érmék cseréje a DEX aggregátor szolgáltatásokkal lehetséges"])},
    "rekey_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ha attól félsz, hogy a számlád privát kulcsa kompromittálódhat, akkor kulcsold át a számládat egy másik privát kulcsra, és az erdeti címet tartsd biztonságban."])},
    "asset_optin_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Egy pénzeszköz fogadásához számlát kell nyitnod az Algorand hálózatban."])},
    "receive_payment_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ha arra kéred az üzletfeleidet vagy barátaidat, hogy küldjenek neked pénzt, akkor ezzel az oldallal egy olyan QR kódot tudsz generálni, amellyel a küldés egy kattintással megoldható."])},
    "export_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A privát kulccsal rendelkező számlákhoz Algorand mnemoninkok vagy Shamir biztonsági mnemonikok állíthatók elő."])},
    "arc14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ARC 14 hitelesítés"])},
    "arc14_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az ARC 14 hitelesítés egy olyan szabvány, amely a hitelesítésre aláírt tranzakciókat használ. A hitelesítési érmék ezen a lapon állíthatól elő."])},
    "account_actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Számla műveletek"])},
    "asset_optinArc200": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benevezés egy ARC200 pénzeszközbe"])},
    "asset_optinArc200_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A számládon megfigyelheted az ARC200 pénzeszközöket. Az ARC200 pénzeszközök olyan okosszerződések, melyekbe bárki benevezhet, ha szeretne ARC200 pénzeszközt fogadni. Az ARC200 pénzeszközök veszélyesek lehetnek, és az okosszerződés alapos vizsgálatára van szükség a teljes bizalom érdekében."])},
    "scheduled_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scheduled payment"])},
    "scheduled_payment_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can setup and manage a scheduled periodic payments to another accounts"])},
    "hide_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hide account"])},
    "unhide_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unhide account"])},
    "vote_first_round": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vote First Round"])},
    "vote_last_round": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vote Last Round"])},
    "vote_key_dilution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vote Key Dilution"])},
    "selection_key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selection Key"])},
    "vote_key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vote Key"])},
    "stateproof_key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["StateProof Key"])},
    "realm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Realm"])},
    "arc14msig_process": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please sign ARC14 authentication transaction and return back to this form."])},
    "hasArc14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ARC14 auth has been loaded."])},
    "generating_keys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generating participation keys.. Please be patient, the participation node is performing CPU sensitive task."])},
    "button_sign_keyreg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign key registration tx"])},
    "button_close_custom_keyreg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close KeyReg tx form"])},
    "button_activate_wizzard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activate wizard"])},
    "button_fetch_realm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Step 1/4: Fetch ARC14 realm"])},
    "button_sign_arc14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Step 2/4: Sign ARC14 realm"])},
    "button_sign_arc14_msig": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Step 2/4: Multisign ARC14 realm"])},
    "button_load_participatin_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Step 3/4: Load participation data"])},
    "button_sign_keyreg_tx": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Step 4/4: Sign participation tx"])},
    "button_custom_keyreg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom KeyReg tx"])},
    "making_account_online": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Setting your account to online state. Please wait a while"])},
    "making_account_offline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Setting your account to offline state. Please wait a while"])}
  },
  "acc_overview_assets": {
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Név"])},
    "search_by_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keresés név szerint"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Típus"])},
    "search_by_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keresés pénzeszköz típus szerint"])},
    "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Id"])},
    "search_by_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keresés pénzeszköz azonosító szerint"])},
    "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Összeg"])},
    "search_by_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keresés összeg szerint"])},
    "actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Műveletek"])}
  },
  "optin": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Egy adott pénzeszköz fogadásának engedélyezése, számla:"])},
    "searchById": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tudom, hogy mi a pénzeszköz azonosítója"])},
    "assetId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pénzeszköz azonosító"])},
    "assetName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pénzeszköz név"])},
    "searchButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pénzeszköz keresés"])},
    "assetInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Információ a pénzeszközről"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Összesen"])},
    "unit_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Egység neve"])},
    "decimals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tizedes jegyek"])},
    "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL"])},
    "metadata_hash": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metaadat hash"])},
    "default_frozen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kezdetben befagyasztva"])},
    "creator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Létrehozó címe"])},
    "manager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kezelő címe"])},
    "clawback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visszakövetelő címe"])},
    "freeze": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Befagyasztó címe"])},
    "reserve": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kibocsátó címe"])},
    "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Művelet"])},
    "optin_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benevezés"])},
    "dialog_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pénzeszköz fogadásának engedélyezése"])},
    "optin_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Biztosan ki akarsz fizetni 0,001 Algót, hogy engedélyezd ennek az eszköznek a fogadásást? Az engedélyezés után képes leszel arra, hogy ilyen eszközt a fogadj a számládon."])},
    "optin_confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Engedélyezni akarom a pénzeszközt"])},
    "asset_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nincs ilyen pénzeszköz"])},
    "asset_opt_in_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sikeresen engedélyezted a pénzeszközt"])}
  },
  "accounts": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Számlák áttekintése"])},
    "no_accounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nincs egyetlen egy számla sem"])},
    "account_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Számla neve"])},
    "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Összeg"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cím"])},
    "pay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kifizetés"])},
    "connect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WalletConnect"])},
    "show_on_netowork_accounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hálózati számlák listázása"])},
    "2fa_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2-ik faktor kódja"])}
  },
  "newacc": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Új számla"])},
    "create_basic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Egyszerű számla létrehozása"])},
    "import_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Számla importálása"])},
    "create_multisign_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multisig számla létrehozása"])},
    "watch_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Számlaegyenleg megfigyelése"])},
    "write_mnemonic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Írd le a 25 szavas mnemonikus kifejezést"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belső számlanév"])},
    "create_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Számla létrehozás"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cím"])},
    "multisig_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A multisig számla csak akkor dolgozza fel a tranzakciókat, ha a számla létrehozásakor kilistázott N számla aláírja a tranzakciót."])},
    "select_account_from_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Válassz egy létező számlát a pénztárcádban"])},
    "add_other_accounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add meg a barátaid számláit - soronként egyet"])},
    "trashold_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Válaszd ki, hogy hány számla szükséges egy tranzakció aláírásához"])},
    "create_account_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A számla létrehozásakor mentsd el a mnemonikot. Biztonságos, ha ennek megjelenítése most történik?"])},
    "show_mnemonic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A mnemonik megjelenítése"])},
    "position_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Milyen szó van az n-ik helyen"])},
    "mnemonic_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Írd le a mnemonik szavait a megjelenítés sorrendjében, másold le a szavakat vagy készíts egy fényképet. A következő lépésben a mnemonik egyes szavait kell újra megadnod, ezt követően a mnemonikot titkosított formában elmentjük a böngésző tárába. A mnemonik tárolása és a cím használata biztonságosan történik. Vigyázz, hogy el ne veszítsd a mnemonikot."])},
    "start_challenge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A kihívás kezdete"])},
    "create_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Új készítése"])},
    "hide_mnemonic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A mnemonik elrejtése"])},
    "drop_phrase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A kifejezés elvetése"])},
    "scan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["QR-kód pásztázás"])},
    "create_vanity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kérkedő számlanév"])},
    "vanity_start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A cím ezzel kezdődik:"])},
    "vanity_mid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A cím tartalmazza:"])},
    "vanity_end": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A cím ezzel végződik:"])},
    "vanity_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feldolgozott címek száma"])},
    "vanity_button_start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cím keresése"])},
    "vanity_button_stop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keresés leállítása"])},
    "vanity_workers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Használni kívánt szálak száma"])},
    "vanity_use": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ennek a számlának a használata"])},
    "auto_lock_off": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az automatikus zárolás funkció kikapcsolva"])},
    "ledger_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HW pénztárca - Ledger számla"])},
    "wc_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WalletConnect számla"])},
    "twofa_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2 faktoros számla"])},
    "show_qr_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["QR kód megjelenítése"])},
    "email_pass_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email & jelszó számla"])},
    "shamir_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visszaállítás Shamir bizonsági mentésből"])},
    "overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Új számlára vonatkozó műveletek"])},
    "create_basic_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az Algorand alapszámla egy ED25519 számla, amelyhez egy privát kulcs tartozik. Ez a módszer a számládhoz tartozó titok előállítására a számítógéped véletlenszám generátorát használja."])},
    "ledger_account_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A legbiztonságosabb megoldás az, ha a titkos kulcs előállítását egy erre specializált berendezés végzi. Ez a számla a Ledger eszközét használja a számla létrehozására és az aláírási műveletek kezelésére."])},
    "wc_account_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A WalletConnect számlatípussal hozzá tudsz kapcsolódni a barátod pénztárcájához, ily módon például el tudod neki küldeni azt a kérést, hogy egy multisig részeként írja alá az üzenetet."])},
    "twofa_account_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A két faktoros számla egy olyan multisig számla, amelyben az első számla egy általad választott tetszőleges számla, például egy Ledger számla vagy egy email, jelszó számla, a második számla pedig visszaállításra használt számla - ideális esetben egy olyan számla, amely nem ugyanabban a biztonságos tárolóban van, egy harmadik számla pedig a szerveren lévő, második faktorhoz tartozó számla. A szerver akkor írja alá ezzel a számlával a tranzakciókat, ha helyesen megadtad a 2-ik faktorhoz tartozó kódot."])},
    "email_pass_account_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az ARC76 email & jelszó számlákkal tetszőleges szövegből Algorand számlák állíthatók elő. A szöveg hash-elése révén 32 byte áll elő, amely az algorand számla kezdőértéke. Ez a számlatípus a számítógépeden található véletlenszám generátorok vagy HW eszközök alternatívája, amely a két faktoros hitelesítésnél vagy általában a multisig számláknál használható."])},
    "create_vanity_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A nyers erő módszerével az ED25519 számládhoz szép számlacímek állíthatók elő. A módszer a számla előállításkor sok kombinációt kipróbál, és lehetővé teszi, hogy megadd a számla címének első és utolsó betűit."])},
    "create_multisign_account_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A multisig számlák a legbiztonságosabb számlák. Ha multisig számlákat a Ledger eszközökkel és különböző véletlenszám generátorokkal együttesen használjuk, akkor ezzel vállalati szintű biztonsághoz jutunk, amely közvetlenül az Algorand blokkláncba van bekódolva."])},
    "watch_account_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Követheted a hálózat bármely számlájának egyenlegét."])},
    "import_account_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visszaállíthatod a számlát az Algorand visszaállító mnemonikból."])},
    "shamir_account_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visszaállíthatod a számlát a Shamir biztonsági mentéshez tartozó mnemonikokból."])},
    "create_first": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hozd létre az első számlát"])},
    "no_accounts_at_network": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nincsek számláid a kiválasztott hálózaton"])},
    "basic_options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alapbeállítások"])},
    "advanced_options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speciális beállítások"])},
    "skip_challange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skip challange"])},
    "new_account_challange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New account challange"])},
    "click_to_show_positions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click to show positions"])},
    "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["to"])}
  },
  "arc76account": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email és jelszó számla (ARC-0076)"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az email címed és a jelszavad egy kombinációjának hash-elésével előállíthatók az első Algorand számládhoz tartozó kezdőértékek. Az email és a jelszó csak a számla átkulcsolása után változtatható meg."])},
    "description2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az ARC-0076 szabvány a PBKDF2-999999-SHA256 algoritmust használja. Azt ajánljuk, hogy a jelszó hossza legalább 50 karakter legyen. Ez a számlatípus másodlagos véletlenszám generátorként használható, és fokozza a biztonságot. A titkos értéket tartsd biztonságban, és ne használd fel újra."])},
    "arc_draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az ARC-0076 jelenleg csupán tervezet. A specifikáció megváltozhat. A jelenlegi megvalósítás kísérletinek tekinthető."])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "select_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Válassz egy nagyon-nagyon-nagyon biztonságos jelszót."])},
    "save_password_switch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jelszó mentés"])},
    "password_not_stored": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Figyelem! Csak a számla nyilvános címét és az email címedet tároljuk, a jelszavadat nem. Ha ezzel a számlával akarsz tranzakciókat létrehozni, akkor újra meg kell adnod a jelszót."])},
    "gui_not_implemented": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az a segédprogram, amellyel az tranzakció a jelszó ismételt beadása nélkül is aláíratható, jelenleg nincs megvalósítva."])}
  },
  "import": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pénztárca importja"])},
    "wallet_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pénztárca neve"])},
    "wallet_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A pénztárcához tartozó állomány"])},
    "import_wallet_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pénztárca importálása"])},
    "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az előzetesen kiexportált pénztárcák beimportálhatók. A pénztárca jelszó az exportált állományon belül van, ezért a pénztárca az importálás után ugyanazzal a jelszóval használható. A bejelentkezés után a jelszó a 'Beállítások' lapon változtatható meg."])}
  },
  "acc_type": {
    "basic_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Egyszerű számla"])},
    "multisig_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multisig számla"])},
    "public_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nyilvános számla"])},
    "rekeyed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Átkulcsolt számla"])},
    "ledger_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ledger HW számla "])},
    "wc_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WalletConnect számla"])}
  },
  "account": {
    "select_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Számla választás"])}
  },
  "pay": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kifizetés indítása innen: "])},
    "toggle_camera": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fényképezőgép be/kikapcsolása"])},
    "note_is_b64": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A 'Megjegyzés' base64 kódolású. Ha engedélyezed, akkor base64 formátumban adhatod meg az inputot a megjegyzés mezőben, és ilyen formátumban küldheted el a hálózatnak."])},
    "selected_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kiválasztott számla"])},
    "multisig_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multisig számla"])},
    "create_proposal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajánlat készítése"])},
    "sign_proposal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajánlat aláírása & elküldése"])},
    "signature_from_friend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kérjük, itt add be a barátodtól származó aláírást"])},
    "load_multisig_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multisig adat betöltése"])},
    "pay_to_wallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fizetés a pénztárcában lévő számlának"])},
    "pay_to_other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fizetés egy másik számlának"])},
    "store_other_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jobb, ha azt a számlát, amelynek a kifizetés történik, a címtárban tárolod"])},
    "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Összeg"])},
    "fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Díj"])},
    "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Megjegyzés"])},
    "review_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A kifizetés áttekintése"])},
    "review_payment_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kérjük, tekintsd át a kifizetést"])},
    "from_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erről a számláról"])},
    "pay_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fizetés erre a számlára"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Összesen"])},
    "transaction_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A tranzakció részletei"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Típus"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Név"])},
    "first_round": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FirstRound"])},
    "last_round": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LastRound"])},
    "genesis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GenesisID"])},
    "tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tag"])},
    "to_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kinek"])},
    "signatures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aláírások"])},
    "signed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aláírva"])},
    "not_signed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nincs aláírva"])},
    "sign_with": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aláírás ezzel"])},
    "sign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aláírás"])},
    "send_to_other_signators": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ennek az adatnak az elküldése a többi aláírónak"])},
    "send_to_network": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elküldés a hálózatnak"])},
    "state_sending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az utalás elküldése a hálózatnak"])},
    "state_sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az utalás elküldve a hálózatnak. Tx: "])},
    "state_waiting_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Várakozás a hálózat megerősítésére."])},
    "state_confirmed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A megerősítés megérkezett. Az utalás bekerült a blokkba"])},
    "transaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tranzakció"])},
    "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hiba"])},
    "asset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eszköz"])},
    "state_error_not_sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az utalás valószínűleg nem jutott el a hálózatra. Offline módba kerültél? Kérjük, ellenőrizd a számládat."])},
    "set_max": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximum beállítása"])},
    "environment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Környezet"])},
    "pay_to_address_malformed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A 'Kinek' cím szabálytalan."])},
    "asset_failed_to_load": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A kívánt pénzeszköz betöltése nem sikerült"])},
    "asset_too_small_balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az egyenleged kisebb a kívánt összegnél"])},
    "rekey_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Átkulcsolás erre a számlára"])},
    "rekey_to_wallet_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Átkulcsolás pénztárca számlára"])},
    "rekey_to_external_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Átkulcsolás külső számlára"])},
    "rekey_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Számla átkulcsolás"])},
    "rekey_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nagyon figyelj ennél a tranzakciónál. Ettől kezdve a számla egy másik privát kulcsot fog használni a trazakciókhoz. Ha nem ismered az új számlához tartozó privát kulcsot, akkor nem fogod tudni aláírni a tranzakciókat."])},
    "combine_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A többi aláíró aláírásainak bedolgozása"])},
    "combine_action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aláírások bedolgozása"])},
    "return_to_wc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visszatérés a WalletConnect-hez"])},
    "select_account_for_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Válaszd ki, hogy melyik számláról szeretnél fizetési műveletet indítani"])},
    "create_multisig_proposal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multisig javaslat létrehozása"])},
    "2fa_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2-ik faktor kód"])},
    "sign_arc14_request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Megbízás aláírása"])},
    "toggle_send_to_others_form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Átkapcsolás a 'Küldés a többeknek' formra"])},
    "toggle_combine_with_others_form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Átkapcsolás a 'Többi aláírás bedolgozása' formra"])},
    "process_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fizetési művelet feldolgozása"])},
    "tx_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tranzakció típus"])}
  },
  "settings": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beállítások"])},
    "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szerver"])},
    "environment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["környezet"])},
    "mainnet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mainnet"])},
    "testnet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Testnet"])},
    "devnet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Devnet elérése"])},
    "sandbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sandbox"])},
    "host": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["gazdagép"])},
    "token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["érme"])},
    "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nyelv"])},
    "pass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pénztárca jelszó"])},
    "oldpass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Régi jelszó"])},
    "newpass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Új jelszó"])},
    "repeatpass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Új jelszó - megismételve"])},
    "backup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pénztárca mentés"])},
    "backup_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A pénztárca elmenthető és egy másik számítógépen beimportálható."])},
    "create_backup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mentés készítése"])},
    "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Letöltés"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pénztárca törlés"])},
    "strength": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erősség"])},
    "updated_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A jelszó módosult"])},
    "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Egyedi szerver beállítások"])},
    "protocol_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Protokoll kezelő regisztrálása"])},
    "protocol_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["web+algorand:// kezelő regisztrálása"])},
    "protocol_change_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A protokoll kezelő módosítása sikeres volt"])},
    "dev_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Információ fejlesztők számára"])},
    "update_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jelszó módosítás"])}
  },
  "transaction": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tranzakció részletei"])},
    "tr_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tranzakció ID"])},
    "tr_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tranzakció típus"])},
    "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Megjegyzés"])},
    "note_raw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formázatlan adat"])},
    "note_decoded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dekódolt adat"])},
    "tr_close_rewards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jutalmak lezárása"])},
    "closing_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Záráskori összeg"])},
    "confirmed_round": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Megerősített menet"])},
    "fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Díj"])},
    "first_valid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Első érvényes"])},
    "genesis_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Genezis id"])},
    "genesis_hash": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Genezis hash"])},
    "intra_round": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belső eltolás"])},
    "last_valid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utolsó érvényes"])},
    "payment_tr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fizetési tranzakció"])},
    "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Összeg"])},
    "close_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Záráskori összeg"])},
    "receiver": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Címzett"])},
    "receiver_rewards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Címzett jutalma"])},
    "round_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Egy menet ideje"])},
    "sender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Küldő"])},
    "sender_rewards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Küldő jutalma"])},
    "signature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aláírás"])},
    "created_asset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A létrejött eszköz"])},
    "asset_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eszköz neve"])},
    "asset_creator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eszköz létrehozó"])},
    "asset_manager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eszköz kezelő"])},
    "asset_reserve": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eszköz kibocsájtó"])},
    "asset_freeze": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eszköz befagyasztó"])},
    "asset_clawback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eszköz visszakövetelő"])},
    "asset_unitName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eszköz egységének a neve"])},
    "asset_total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Összes eszköz"])},
    "asset_decimals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tizedesjegyek száma"])},
    "asset_defaultfrozen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kezdetben legyen befagyasztva"])},
    "asset_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eszköz url"])},
    "asset_metadata": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eszköz metaadat"])},
    "time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Idő"])},
    "group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Csoport ID"])}
  },
  "privacy": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adatvédelmi irányelvek"])},
    "q1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Milyen információkat gyűjtenek rólam?"])},
    "a1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Semmilyeneket, az IP címeden és böngésző típusodon kívül, amit minden internet szolgáltató tárol. Nem gyűjtünk semmilyen személyes adatot sem, pl. neveket, e-mail címeket vagy Algorand címeket."])},
    "q2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mi van sütikben tárolva?"])},
    "a2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Semmi. Nem használunk sütiket. Egy indexdb nevű perzisztens lokális tárat használunk a pénztárca tárolására."])},
    "q3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hogyan történik az analitikai adatok gyűjtése?"])},
    "a3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nem gyűjtünk ilyen adatokat. Semmilyen analitikai szolgáltatást sem használunk, pl. a Google analitikát sem, hogy a felhasználók nagyobb biztonságban érezhessék magukat. Az alkalmazás tisztán html és javascript, és csak akkor tudjuk meg az IP címedet, ha egy kérést küldesz a szervernek. A beállításaidtól és az algod szerveredtől ill. indexelő szolgáltatásodtól függően a kiválasztott szerver szintén tudomást szerezhet az IP címedről."])},
    "q4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Érinti-e ennek a szolgáltatásnak a használata, hogy milyen reklámokat látok?"])},
    "a4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nem. A reklám szolgáltatók semmilyen adatot sem kapnak tőlünk a használatra vonatkozóan. Például a Google csupán úgy képes tudomást szerezni arról, hogy használod ezt a szolgáltatást, ha a webhelyet a Google keresésből éred el."])},
    "q5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ki nyújtja a szolgáltatást?"])},
    "a5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ezt a szolgáltatást a Scholtz & Company, Kkt. hozta létre. Ha a pénztárca a Scholtz & Co által biztosított hivatalos web helyen fut, akkor a cég egyúttal a szolgáltatásnyújtó. A cég nem felel semmilyen szolgáltatásért, sem az alkalmazás esetleges hibáiért."])}
  },
  "assetcreate": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Új NTF / ASA"])},
    "show_advanced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speciális beállítások megjelenítése"])},
    "assetName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Barátságos név"])},
    "creator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A létrehozó címe"])},
    "hide_advanced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speciális beállítások elrejtése"])},
    "creator_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cím létrehozása vagy cím első beimportálása"])},
    "default_fronzen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Befagyasztva: a felhasználói számlákat fel kell oldani a tranzakciók végzése előtt"])},
    "decimals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tizedesjegyek: a tizedes jegyek számát megadó egész szám, mely az eszköz egységeinek a számítására használatos"])},
    "totalIssuance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ennek az eszköznek a forgalomban lévő összes darabszáma"])},
    "unitName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arra használjuk, hogy az eszköz egységeit megjelenítsük a felhasználó számára"])},
    "assetURL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Egy opcionális string, amely az eszközhöz tartozó URL-re mutat"])},
    "assetMetadataHash": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Egy opcionális hash, amely valamilyen módon az eszközhöz kapcsolódik. Base64 format."])},
    "manager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A kezelő módosítani tudja az eszköz kezelőjének, kibocsátójának, befagyasztójának és visszakövetelőjének a számlacímét"])},
    "reserve": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kibocsátó cím: A megadott címet tekinti az eszköz kibocsátójának (nincs semmi különös privilégiuma, csak informális)"])},
    "freeze": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Befagyasztási cím: A megadott cím képes a felhasználó pénzeszközének befagyasztására vagy a befagyasztás feloldására"])},
    "clawback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visszakövetelési cím: A megadott cím képes a felhasználó pénzeszközét visszavenni és elküldeni egy másik címre"])},
    "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A tranzakció létrehozásakor megadott megjegyzés"])},
    "create_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NFT / ASA létrehozás"])}
  },
  "new_account_ledger": {
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Számla létrehozás Ledger-rel"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A Ledger egy fizikai eszköz, egy HW pénztárca, amely a privát kulcsaid tárolását végzi. Az Algorandban a pénzeszközök a legbiztonságosabban olyan multisig számlával tárolhatók, amelynél mindegyik aláíró egy Ledger eszközzel rendelkezik."])},
    "last_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utolsó hiba"])},
    "connect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Összekapcsolódás a Ledger-rel és egy Algorand cím betöltése"])},
    "slot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rekesz (Slot)"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cím"])},
    "primary_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elsődleges cím"])},
    "account_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Számlanév"])},
    "save_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A cím mentése a pénztárcába"])}
  },
  "faq": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gyakran ismételt kérdések"])},
    "q1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A pénztárcáról"])},
    "a1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az Algo pénztárca alkalmazást eredetileg a Scholtz & Company készítette. Nyílt forráskódú, tisztán html-ben íródott, fő előnye a könnyű használhatóság és a biztonság."])},
    "q2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mennyire biztonságosan vannak a pénztárca adatai tárolva?"])},
    "a2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az adatok csak a böngészőn belül vannak meg. Az adatok egy perzisztens index db-ben vannak tárolva. A pénztárcák titkosítása aes256-tal, jelszó segítségével történik. Ha a jelszó elég erős, akkor a pénztárca nyers erővel soha sem lesz feltörhető. A jelszó nem hagyja el a számítógépedet, és csak a böngésző gyorsítótárában van meg. A bejelentkezés után 5 perccel a böngésző gyorsítótára törlődik."])},
    "q3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kikerül-e a pénztárcám jelszava az Internetre?"])},
    "a3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nem, soha"])},
    "q4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hogyan tudom megváltoztatni a pénztárca jelszavát?"])},
    "a4_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jelentkezz be a pénztárcádba"])},
    "a4_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menj a 'Beállítások'-hoz"])},
    "a4_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gépeld be a régi jelszót, az új jelszót, majd ismételten az új jelszót."])},
    "a4_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az űrlap elküldése után a jelszó megváltozik."])},
    "q5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Át tudom vinni a pénztárcámat egy másik számítógépre?"])},
    "a5_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jelentkezz be a pénztárcádba"])},
    "a5_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menj a 'Beállítások'-hoz"])},
    "a5_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menj a 'Biztonsági mentés' (backup) részhez és mentsd az adataidat egy helyi állományba."])},
    "a5_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A másik számítógépen menj az 'Új pénztárca', 'Pénztárca importja' menüponthoz, és töltsd föl az állományt a másik böngésző tárterületére."])},
    "a5_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A pénztárca ugyanazzal a jelszóval lesz elérhető, mint azelőtt. A jelszó a 'Beállítások'-ban változtatható meg."])},
    "q6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tudom ellenőrizni a forráskódot?"])},
    "a6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Igen, a https://github.com/scholtz/wallet/ címen. A pénztárca nyílt forráskódú és szabadon használható, másolható vagy módosítható, még kereskedelmi célokra is. Ha szeretnél résztvenni a projektben, akkor hozz létre egy 'pull request'-et, amely a javításaidat tartalmazza."])},
    "q7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hogyan tudom segíteni a projektet?"])},
    "a7_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az AWALLETCPHQPJGCZ6AHLIFPHWBHUEHQ7VBYJVVGQRRY4MEIGWUBKCQYP4Y Algorand címre küldött adományokkal lehet a projektnek segítséget nyújtani. A projektet sem az Algorand Inc., sem az Algorand Foundation nem támogatta."])},
    "a7_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ha a projektet szeretnéd lefordítani más nyelvre, menj a https://github.com/scholtz/wallet/tree/master/src/locales web helyre, fordítsd le az állományt a saját anyanyelvedre, és végezz egy 'pull request'-et."])},
    "a7_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ha programozó vagy, végezz kódvizsgálatot, vagy jelezd, hogy miképpen lehetne a projektet jobbá tenni."])},
    "q8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hogyan készíthetők multisig számlák?"])},
    "a8_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Először hozz létre privát kulcsokkal rendelkező számlákat vagy hozz létre nyilvános számlákat. Jobb, ha elnevezed a számlákat, hogy később emlékezz rá, milyen célból hoztad őket létre."])},
    "a8_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menj az 'Új cím létrehozásá'-hoz, és válaszd a multisig számlát."])},
    "a8_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Válaszd ki az összes számlát, amely aláírhatja a tranzakciókat, és határozd meg, hogy minimum hány aláírásra legyen szükség ahhoz, hogy a hálózat elfogadja a multisig tranzakciót."])},
    "a8_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A multisig számla a létrehozása után a 'Számlák listázása' lapról érhető el."])}
  },
  "connect": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DApp-okhoz történő kapcsolódás a WalletConnect segítségével"])},
    "uri": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WalletConnect URI"])},
    "connect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kapcsolódás"])},
    "disconnect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szétkapcsolás"])},
    "sendBack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visszaküldés a DApp-nak"])},
    "reject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elutasítás"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cím"])},
    "or": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["vagy"])},
    "authaddr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meghatalmazott címe"])},
    "index": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Index"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Típus"])},
    "from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Honnan"])},
    "asset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pénzeszköz"])},
    "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mennyiség"])},
    "rekeyto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Átkulcsolás ide"])},
    "method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Módszer"])},
    "client_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kliens ID"])},
    "request_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kérés ID"])},
    "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Törlés"])},
    "peer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peer"])},
    "connected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Összekapcsolódva"])},
    "sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Munkamenetek"])},
    "requests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kérések"])},
    "fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Díj"])},
    "total_fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teljes díj"])},
    "sign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aláírás"])},
    "clipboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kapcsolódás a vágólapról"])},
    "sign_txs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kérlek, írd alá az egyes tranzakciókat"])},
    "signed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aláírva"])},
    "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hová"])},
    "validity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Érvényesség"])},
    "rounds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["menetek"])},
    "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Megjegyzés"])},
    "group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Csoport"])},
    "app": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["App index"])},
    "app_args": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["App argumentumok"])},
    "app_accounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["App számlák"])},
    "app_assets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["App idegen pénzeszközök"])},
    "boxes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Box-ok"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Név"])},
    "genesis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Genezis ID"])},
    "genesis_hash": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Genezis hash"])},
    "session_proposals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Munkamenet javaslatok"])},
    "toggle_camera": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kamera be/kikapcsolás"])},
    "init_wc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kapcsolat inicializálása a WalletConnect-hez"])},
    "sign_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az összes aláírása"])},
    "proposer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proposer"])},
    "all_accounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use all wallet accounts"])}
  },
  "new_account_wc": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WalletConnect számla létrehozása"])},
    "account_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Számla név"])},
    "last_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utolsó hiba"])},
    "scan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["QR kód bepásztázása"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cím"])},
    "save_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A cím elmentése a pénztárcába"])},
    "copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az URI másolása a vágólapra"])},
    "show_qr_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["QR kód kérés"])}
  },
  "new_account_2fa": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2-faktoros számla"])},
    "help1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A 2-faktoros számlával olyan multisig számla hozható létre, amely 3 számlából áll, és az aláírási határ 2."])},
    "help2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az első számla egy jelszóval védett számla lehet, ami a pénztárca meghekkelésekor önmagában nem elégséges a tranzakciók aláírásához. Ha a második faktort előállító eszközt feltörik vagy a második faktorhoz tartozó szolgáltatás kiesik, akkor át tudod kulcsolni az első és második számládat egy másik 2-faktoros multisigre."])},
    "help3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A második faktorhoz tartozó szolgáltatás egy harmadik fél szolgáltatása, amely kompromittálódhat. Ha egy hacker megszerzi a privát kulcsodat és a második faktorhoz tartozó szolgáltatás privát kulcsát, akkor képes lesz a nevedben aláírni a tranzakciókat. A 2-faktoros számla használatával valószínűbbé tehető, hogy az a hacker, aki alá tudja írni az elsődleges vagy másodlagos számládat, nem lesz képes a multisig számla tranzakcióinak az aláírására."])},
    "2fa_provider": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A 2-ik faktor szolgáltatója"])},
    "hot_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Főszámla"])},
    "cold_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tartalék számla"])},
    "request_qr_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2-ik faktor QR kód kérés"])},
    "scan_qr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pásztázd be a QR kódot az Authentikátor alkalmazásoddal"])},
    "manual_entry_key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kézi kulcsbevitel"])},
    "confirm_2fa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A beállítás ellenőrzése"])},
    "confirm_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A beállítás ellenőrzéséhez írd be az Authentikátor alkalmazásodból származó kódot. Az ellenőrzés után már nem lehet újrakérni a 2-ik faktorhoz tartozó titkos kódot."])},
    "2fa_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2-ik faktor kódja"])},
    "save_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cím mentése"])}
  },
  "onlineofflinedialog": {
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Védd a hálózatot a számlaegyenlegeddel"])},
    "warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kiválaszthatsz egy nyilvános hálózati csomópontot, amellyel a hálózat blokkjai ellenőrizhetők. Az új blokkok alkotásához szükséges részvételi kulcsok (participation keys) ezen a nyilvános algod szerverek fognak előállni, az aláírásoddal pedig engedélyezheted, hogy ezek a részvételi kulcsok részt vegyenek a blokkok előállításában. Ennek költsége csupán egy hálózati tranzakciót (0,001 Algó) tesz ki. A számla online lekötése nem azt jelenti, hogy a privát kulcsod elérhető lesz az interneten, hanem azt, hogy a számlád regisztrálva lett az online algod csomópontbban, amely védi a blokkláncot."])},
    "makeOnline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A számla online-ba állítása"])},
    "makeOffline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A számla offline-ba állítása"])},
    "host": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Algod számítógép"])},
    "fromclipboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Process data from clipboard"])},
    "stakingRegistration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register for staking - 2A network fee"])},
    "stakingHelp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By paying 2 algorand now as network fee, and having more than 30k algos on your account, you are eligible to receive the staking rewards anytime the staking node mints new block."])},
    "onlineRounds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of rounds to protect the network"])}
  },
  "new_account_shamir": {
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Számla helyreállítás Shamir biztonsági mentésből"])},
    "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A Shamir biztonsági mentés a mnemonikot N részre osztja, ezekből M szükséges a számla helyreállításához."])},
    "mnemonic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mnemonik"])},
    "add_mnemnic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mnemonik beadása"])},
    "account_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A számla neve"])},
    "recover": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Helyreállítás"])}
  },
  "account_export": {
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Számla exportálás"])},
    "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ezen a lapon exportálhatod a privát kulcsodat, Algorand mnemonik vagy Shamir biztonsági mentés formájában."])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add meg a pánztárcád jelszavát"])},
    "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Folytatás"])},
    "algo_mnemonic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Algorand mnemonik"])},
    "shamir_backup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shamir biztonsági mentés"])},
    "toggle_qr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["QR kód átkapcsolás"])},
    "recovery_threshold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visszaállítási küszöb"])},
    "number_of_mnemonics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mnemonikok száma"])},
    "generate_shamir": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shamir mnemonikok előállítása"])},
    "previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Előző"])},
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Következő"])},
    "index": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shamir index"])},
    "shamir_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A mnemonikot nagyon biztonságos helyen tárold. Ellenőrizd, hogy leírtad-e a Shamir biztonsági mentés összes részét, és egy helyen csak a visszaállítási küszöbnél kevesebb mnemonikot tárolj."])},
    "algo_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A mnemonikot nagyon biztonságos helyen tárold."])},
    "dev_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fejlesztői információ"])}
  },
  "arc200": {
    "optin_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A számla bővítése egy ARC 200 pénzeszközzel"])},
    "app_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ARC200 App ID"])},
    "fetch_action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A pénzeszközre vonatkozó adatok beolvasása"])},
    "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alaphelyzetbe állítás"])},
    "examples": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Példa pénzeszközök"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Név"])},
    "symbol": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szimbólum"])},
    "decimals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tizedesjegyek száma"])},
    "supply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Összes készlet"])},
    "balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Számla egyenleg"])},
    "is_opted_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A számla képes a pénzeszköz fogadására"])},
    "create_box": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A számlához, ezen belül a pénzeszközhöz egy tároló (box) létrehozása"])},
    "save_action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pénzeszköz mentése a számlába"])}
  },
  "scheduled_payments": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scheduled payments management"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["With decentralized scheduler you will deploy the smart contract - escrow account which will be used for purpose to allow any executor perform withdrawal from your escrow account to the destination account when the time comes. Executors are incentivized to execute your scheduled task with vision of receiving small fee paid in ASA.Gold token - 0.001 gram of gold."])},
    "new_scheduled_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New scheduled payment"])},
    "period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Period"])},
    "pay_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pay to"])},
    "send_asset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asset to send"])},
    "create_escrow_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create escrow account"])},
    "list_of_payments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List of active payments"])},
    "no_payment_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No scheduled payments has been found"])},
    "app_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Escrow app id"])},
    "app_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Escrow app address"])},
    "start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start"])},
    "fee_balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fee balance"])},
    "execution_fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Execution fee"])},
    "description_detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In this detail overview of the scheduled payment you can see the current asset balance of the escrow account and you have actions to deposit more assets to the escrow account or to executors."])},
    "details_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scheduled payment details"])},
    "seconds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["seconds"])},
    "deposit_fee_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deposit asa.gold token to fee pool smart contract"])},
    "fee_asset_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Execution fee token"])},
    "amount_to_deposit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount to deposit to fees"])},
    "get_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get more tokenized gold"])},
    "list_of_assets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List of assets at escrow account"])},
    "assets_loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asset information is loading"])},
    "asset_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asset ID"])},
    "asset_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asset name"])},
    "asset_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asset amount"])},
    "deposit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deposit"])},
    "optin_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Escrow account optin action"])},
    "optin_to_asset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opt in to asset"])},
    "optin_click": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opt in"])},
    "withdraw_asset_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Withdraw from escrow account"])},
    "withdraw_asset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asset to withdraw"])},
    "withdraw_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount to withdraw"])},
    "withdraw_click": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Execute withdrawal"])},
    "load_script_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Load app execution profile"])},
    "load_click": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Load"])},
    "xgov_promo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This work has been performed with support from the Algorand Foundation xGov Grants Program"])}
  }
}