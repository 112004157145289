export default {
  "404": {
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chyba 404 - Stránka nebola nájdená"])},
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stránka ktorú hľadáte nie je bohužiaľ k dispozícii. Skúste prejsť na hlavnú stránku."])}
  },
  "footer": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A-Wallet - Komunitná algorand peňaženka s otvoreným kódom"])}
  },
  "login": {
    "new_wallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nová peňaženka"])},
    "new_wallet_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Názov peňaženky"])},
    "new_wallet_pass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heslo peňaženky"])},
    "new_wallet_button_create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vytvoriť peňaženku"])},
    "new_wallet_button_import": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importovať peňaženku"])},
    "new_wallet_help1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vaša peňaženka bude uložená v prehliadači. Heslo je potrebné k odomknutiu peňaženky a možnosti odosielania vašich aktív."])},
    "new_wallet_help2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odporúčame použiť veľmi silné heslo. Aspoň 12 znakov s použitím znakov zo skupín malé písmená, veľké písmená, číslice a špeciálne znaky"])},
    "open_wallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otvoriť peňaženku"])},
    "select_wallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vybrať peňaženku"])},
    "wallet_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heslo do peňaženky"])},
    "strength": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sila hesla"])},
    "new_wallet_button_open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otvoriť peňaženku"])},
    "basic_usage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Základné použitie"])},
    "tether_usage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ako používať Tether (USDt)"])},
    "source_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zdrojový kód AWallet"])}
  },
  "global": {
    "go_back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naspäť"])},
    "go_home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domov"])},
    "copy_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skopírovať adresu"])},
    "copied_to_clipboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skopírované"])},
    "stop_camera": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vypnúť kameru"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zrušiť"])},
    "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Áno"])},
    "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie"])},
    "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Šťahujem údaje.."])},
    "keyword_search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fultextové vyhľádávanie"])}
  },
  "navbar": {
    "logo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A Wallet"])},
    "toggle_nav": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prepnúť navigáciu"])},
    "list_accounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zoznam mojich účtov"])},
    "new_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nový účet"])},
    "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nastavenia"])},
    "asset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktíva"])},
    "asset_create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vytvoriť NTF"])},
    "faq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FAQ"])},
    "privacy_policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ochrana osob. údajov"])},
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odhlásenie"])},
    "vote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voting"])},
    "swap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SWAP"])},
    "connect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WalletConnect"])}
  },
  "swap": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Swapnúť tokeny"])},
    "network": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sieť"])},
    "network_not_supported": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sieť nie je podporovaná"])},
    "has_sk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iba štandardné účty s privátnym kľúčom sú aktuálne podporované"])},
    "swap_asset_from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyberte token ktorý chcete predať"])},
    "swap_asset_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyberte token ktorý chcete kúpiť"])},
    "swap_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyberte čiastku tokenu na predaj"])},
    "get_quote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Získať kotáciu"])},
    "apps_optin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SWAP vyžaduje aby ste za zaregistrovali k aplikáciám"])},
    "apps_optin_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zaregistrujte sa k aplikáciám (optin)"])},
    "execute_button_deflex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vykonať swap cez Deflex"])},
    "execute_button_folks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vykonať swap cez Folks router"])},
    "slippage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slippage percentage"])}
  },
  "govtoolsmenu": {
    "gen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generate accounts"])},
    "distribute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distribute algo 1->N"])},
    "optin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opt in for the governance"])},
    "pay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mass pay N->1"])},
    "merge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merge accounts N->1"])}
  },
  "merchant": {
    "make_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Platobná brána"])},
    "pay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zaptaťte"])},
    "to_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["na algorand účet"])},
    "please": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "network": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sieť"])},
    "matching_symbol": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Párovací symbol"])},
    "network_fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poplatok za prevod"])},
    "pay_qr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zaplatiť pomocou QR kódu"])},
    "pay_nativewallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zaplatiť cez algo peňaženku"])},
    "pay_webwallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zaplatiť cez webovú peňaženku"])},
    "cancel_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zrušiť platbu"])},
    "payment_received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Platba bola prijatá."])},
    "go_back_to_merchant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ísť späť na webstránku obchodníka"])},
    "waiting_for_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Čaká sa na platbu. Zaplaťte prosím."])},
    "error_asset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error fetching asset: "])}
  },
  "gateway": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Free opensource payment gateway without middleman"])},
    "perex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start accepting algo, USDC, USDT or other assets on your website. By accepting cryptocurrency without financial middleman you will increase your sales on your website and increase your profit."])},
    "how": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How it works?"])},
    "create_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create unique matching symbol, eg. order id"])},
    "redirect_to_gateway": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redirect user to the gateway"])},
    "customer_pays": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer pays directly to your algorand account"])},
    "customer_redirected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer will be redirected to your website back after successful payment"])},
    "use_your_money": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use your money. You can echange USDT and take USD from the exchange, or you can pay with USDT for other goods or services"])},
    "security": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is it secure?"])},
    "you_make_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You make the payment details. You setup the successful and cancel URL address. We notify you when the payment is done. You have to check in your backend where you accept the payment that it has been received and you notify the customer."])},
    "opensource": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This project is open source. You can clone, modify it, or run in your private environment for commercial purposes"])},
    "just_website": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The payment gateway is just HTML website with javascript. No other backend is used other than official algorand nodes."])},
    "no_restrictions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You do not have AWallet account in order to use the gateway."])},
    "allowed_parameters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allowed parameters"])},
    "parameters_perex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can set any of these parameters. Only required field is the address where you want to receive your payment and transaction amount. The payment address consits of 2 parts. Payment info, and gateway settings. Both fields are base64 encoded separated by /. Please make sure that you url encode them properly. Payment info is the basic algorand protocol address used for example in the QR codes."])},
    "payment_info_params": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allowed parameters"])},
    "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["use the asset base unit. Eg. 1 ALGO = 1000000"])},
    "fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["the amount in microalgos. Eg. 0,001 ALGO = 1000"])},
    "network": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Network for debug purposes"])},
    "settings_params": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allowed settings"])},
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL address where to redirect client after successful payment"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL address where to redirect client after he clicks cancel payment"])},
    "example": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Example"])},
    "turn_on_build_tool": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Turn on the build tool"])},
    "turn_off_build_tool": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Turn off the build tool"])},
    "tether": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tether mainnet"])},
    "algorand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Algorand"])},
    "asset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can specify asset id for the transaction"])},
    "error_transaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please fill in at least account, amount and asset"])},
    "error_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL address must start with https:// protocol"])},
    "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generated button"])},
    "payment_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment info"])},
    "callback_configuration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Callback configuration"])},
    "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generated link"])},
    "html_example": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HTML link example"])}
  },
  "receive": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prijať platbu"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresa príjemcu platby"])},
    "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Čiastka"])},
    "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poznámka"])},
    "decimals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informácia o desatinnej čiarke do kódu"])},
    "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Návrh označenia účtu pre odosielateľa"])},
    "noteeditable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poznámka je editovateľná odosielateľom"])}
  },
  "acc_overview": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prehľad účtov"])},
    "pay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odoslať platbu"])},
    "receive_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prijať platbu"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Názov"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresa"])},
    "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Čiastka"])},
    "amount_without_pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Čiastka bez neprijatých odmien"])},
    "rewards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odmeny"])},
    "pending_rewards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neprijaté odmeny"])},
    "reward_base": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Základ odmien"])},
    "round": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Číslo bloku"])},
    "apps_local_state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stav lokálnych aplikácií"])},
    "apps_total_schema": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Celková schéma aplikácií"])},
    "assets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktíva"])},
    "created_apps": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vytvorené aplikácie"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stav"])},
    "multisignature_threshold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multipodpis limit"])},
    "multisignature_addresses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multipodpis adresa"])},
    "refresh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obnoviť"])},
    "transactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transakcie"])},
    "no_transactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zatiaľ na účte nie sú vykonané žiadne transakcie alebo sa nenačítali"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ"])},
    "time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Čas"])},
    "tr_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Čiastka"])},
    "sender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odosielateľ"])},
    "receiver": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Príjemca"])},
    "receiver_rewards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odmena pre príjemcu"])},
    "confirmed_round": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potvrdený blok"])},
    "fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poplatok"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vymazať účet"])},
    "delete_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vymazať účet"])},
    "delete_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ste si istý, že chcete tento účet vymazať?"])},
    "delete_confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vymazať účet"])},
    "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Účet bol vymazaný"])},
    "asset_optin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pridať aktívum"])},
    "ask_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ask question"])},
    "rekey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rekey account"])},
    "rekeyedTo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rekeyed to account"])},
    "account0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ledger primary account"])},
    "slot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account at ledger slot"])},
    "connect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WalletConnect"])},
    "payment_gateway": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment gateway"])},
    "export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Export"])},
    "no_assets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Na vašom účte zatiaľ nemáte dostupné žiadne tokeny."])},
    "actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Akcie"])},
    "pay_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odošlite peniaze alebo tokeny na iný účet v sieti."])},
    "swap_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Môžete vymeniť jeden token za druhý pomocou služieb DEX aggregátora."])},
    "rekey_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ak sa obávate, že iná osoba môže mať prístup ku privátnemu kľúču ku Vášmu účtu, môžete si nastaviť nový privátny kľúč z inej adresy a môžete naďalej používať svoju adresu účtu."])},
    "asset_optin_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ak chcete prijať na Váš účet tokeny, najskôr si musíte otvoriť účet s týmito tokenmi."])},
    "receive_payment_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ak chcete prijať platbu od Vášho zákazníka alebo kamaráta, môžete vygenrovať platobný QR kód a oni po jeho nascanovaní Vám môžu poslať platbu na jedno kliknutie."])},
    "export_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pre účty s privátnym kľúčom si môžete vygenerať kódy pre obnovenie účtu pomocou sharmir recovery alebo pomocou štandardného algorand mnemonicu."])},
    "arc14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ARC 14 Autorizácia"])},
    "arc14_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ARC 14 je autorizačný štandard ktorý využívat podpísané transakcie. Na tejto stránke si môžete vygenerovať autorizačné tokeny k vášmu účtu a externej službe."])},
    "account_actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Akcie účtu"])},
    "asset_optinArc200": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pridať ARC200 aktívum"])},
    "asset_optinArc200_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Na svojom účte môžete sledovať a robiť prevody ARC200 aktív. ARC200 aktívum je smart kontrakt s výhodou, že otvoriť účet užívateľovi môže ktokoľvek. ARC200 aktívum môže byť nebezpečné a mali by ste vyžadovať bezpečnostný audit smart kontraktu pred tým ako ho začnete používať."])},
    "scheduled_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pravidelné platby"])},
    "scheduled_payment_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Môžete nastaviť a spravovať pravidelné platby ktorými vykonáte platby na Váš alebo cudzí účet"])},
    "hide_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skryť účet"])},
    "unhide_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zobrazovať účet"])},
    "vote_first_round": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Participovať od bloku"])},
    "vote_last_round": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Participovať do bloku"])},
    "vote_key_dilution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vote Key Dilution"])},
    "selection_key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selection Key"])},
    "vote_key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vote Key"])},
    "stateproof_key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["StateProof Key"])},
    "realm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Realm"])},
    "arc14msig_process": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Podpíšte ARC14 autorizačnú transakciu a vráťte sa naspäť na tento formulár."])},
    "hasArc14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ARC14 token bol podpísaný."])},
    "generating_keys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generujem participačné kľúče.. Táto operácia je náročná na výkon a môže chvíľku trvať."])},
    "button_sign_keyreg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Podpísať participačnú transakciu"])},
    "button_close_custom_keyreg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zavrieť formulár"])},
    "button_activate_wizzard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivovať sprievodcu"])},
    "button_fetch_realm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Krok 1/4: Načítať ARC14 realm"])},
    "button_sign_arc14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Step 2/4: Podpísať ARC14 realm"])},
    "button_sign_arc14_msig": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Step 2/4: Podpísať ARC14 realm msig"])},
    "button_load_participatin_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Step 3/4: Vygenerovať participačné kľúče"])},
    "button_sign_keyreg_tx": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Step 4/4: Podpísať participačnú transakciu"])},
    "button_custom_keyreg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vlastná KeyReg tx"])},
    "making_account_online": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nastavujem Váš účet aby participoval na overovaní siete. Táto operácia môže chvíľku trvať."])},
    "making_account_offline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nastavujem Váš účet aby neparticipoval na overovaní siete. Táto operácia môže chvíľku trvať."])}
  },
  "acc_overview_assets": {
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "search_by_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search by name"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
    "search_by_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search by asset type"])},
    "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Id"])},
    "search_by_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search by asset id"])},
    "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount"])},
    "search_by_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search by amount"])},
    "actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions"])}
  },
  "optin": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prijímať aktívum (ASA alebo NFT) na účte"])},
    "searchById": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Viem ID aktíva"])},
    "assetId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID aktíva"])},
    "assetName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Názov aktíva"])},
    "searchButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nájsť aktívum"])},
    "assetInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informácia o aktíve"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Celkovo"])},
    "unit_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jednotka"])},
    "decimals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Des. miesta"])},
    "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL"])},
    "metadata_hash": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metadata hash"])},
    "default_frozen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Predvolene zamrznuté"])},
    "creator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresa tvorcu aktíva"])},
    "manager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresa managera aktíva"])},
    "clawback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresa clawback účtu"])},
    "freeze": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uzamykateľný účet aktíva"])},
    "reserve": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rezervný účet aktíva"])},
    "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Akcia"])},
    "optin_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Akceptovať aktívum"])},
    "dialog_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Akceptovať aktívum"])},
    "optin_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ste si istý, že chcete vykonať prevod za poplatok 0.001 algo a umožňniť prijímať aktívum?"])},
    "optin_confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chcem prijímať aktívum"])},
    "asset_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktívum nebolo nájdené"])},
    "asset_opt_in_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Úspešne ste sa v sieti prihlásili k prijímaniu aktíva"])}
  },
  "accounts": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prehľad účtov"])},
    "no_accounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nevytvorili ste si zatiaľ žiadny účet. Pokračujte vytvorením účtu alebo importovaním účtu."])},
    "account_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Názov účtu"])},
    "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Čiastka"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresa"])},
    "pay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Platba"])},
    "connect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WalletConnect"])},
    "show_on_netowork_accounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zobraziť iba účty pre blockchain"])},
    "2fa_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kód dvoj úrovňovej autorizácie"])}
  },
  "newacc": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nový účet"])},
    "create_basic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vytvoriť bežný účet"])},
    "import_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Import bežného účtu"])},
    "create_multisign_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vytvoriť mulitipodpisový účet"])},
    "watch_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pridať verejný účet"])},
    "write_mnemonic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zapíšte si 25 slov mnemotechnickej frázy"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interný názov účtu"])},
    "create_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vytvoriť účet"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresa"])},
    "multisig_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multipodpisové účty môžu odoslať prostriedky iba ak limitný počet účtov podpíše transakciu."])},
    "select_account_from_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vybrať účet pre podpis zo zoznamu účtov"])},
    "add_other_accounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pridať ďalšie účtu - jeden účet na riadok"])},
    "trashold_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyberte limitný počet účtov ktorý je potrebný pre podpísanie transakcie"])},
    "create_account_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aby ste si mohli vytvoriť účet, uistite sa, že ste si zapísali mnemotechnickú frázu. Je bezpečné zobraziť frázu teraz?"])},
    "show_mnemonic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zobraziť mnemotechnickú frázu"])},
    "position_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aké je slovo na pozícii č."])},
    "mnemonic_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zapíšte si mnemotechnickú frázu v stanovenom poradí, skopírujte si ju na bezpečné zaheslované miesto alebo si urobte screenshot. Nikomu túto frázu neukazujte. Ďalší krok bude potvrdenie, že ste si frázu uložili správne a po potvrdení správneho slova na konkrétnej pozícii uložíme tento účet do zašifrovanej peňaženky vo Vašom prehliadači. Môžete použiť túto frázu aj bez toho aby ste si ju uložili npr v iných peňaženkách. Uistite sa, že ju nikdy ani o 10 rokov nestratíte."])},
    "start_challenge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Začať test"])},
    "create_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vytvoriť novú"])},
    "hide_mnemonic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skryť frázu"])},
    "drop_phrase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahodiť frázu"])},
    "scan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nascanovať z QR"])},
    "create_vanity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Účet s vlastnou adresou"])},
    "vanity_start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Začne na"])},
    "vanity_mid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obsahuje"])},
    "vanity_end": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Končí na"])},
    "vanity_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Počet otestovaných účtov"])},
    "vanity_button_start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nájsť účet"])},
    "vanity_button_stop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stop"])},
    "vanity_workers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Počet vlákien"])},
    "vanity_use": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Použiť tento účet"])},
    "auto_lock_off": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auto zamykanie peňaženky je vypnuté"])},
    "ledger_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HW Zariadenie - Ledger účet"])},
    "wc_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wallet Connect účet"])},
    "twofa_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2FA účet"])},
    "show_qr_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zobraz QR kód"])},
    "email_pass_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Účet z emailu a hesla"])},
    "shamir_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obnova pomocou Shamir kódov"])},
    "overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Akcie pre založenie nového účtu"])},
    "create_basic_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Základný algorand účet je asymetrický účet s privátnym kľúčom na elyptickej krivke ED25519. Táto metóda používa generátor náhodných čísiel vo Vašom počítači pre vytvorenie privátneho kľúču."])},
    "ledger_account_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jedna z najbezpečnejších metód pre vytvorenie účtu na algorande je prenechanie vytvorenia privátneho kľúču na špecializovanom zariadení. Tento účet využíva zariadenie ledger pre vytvorenie účtu aj pre podpisovanie transakcií."])},
    "wc_account_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pomocou tohto účtu si môžete pripojiť účet Vášho kamaráta pomocou wallet connect. Následne im môžete posielať žiadosti o podpis transakcií napríklad pre účely multipodpisových transakcií."])},
    "twofa_account_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Účet dvojúrovňovej autorizácie je multipodpisový účet, kde prvý účet je ľubovoľný účet npr ledger alebo základný algorand účet. Druhý účet je účet pre obnovenie ideálne taký ktorý nikdy nebol online a je veľmi dobre zabezpečený. Tretí účet je serverový účet kde server podpíše transakciu iba v prípade ak zadáte správny pin kód z vašej 2FA aplikácie."])},
    "email_pass_account_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ARC76 Email & Heslo je typ účtu kde pomocou ľubovoľného textu sa Vám vygeneruje algorand účet. Tento text môžete považovať ako za ďalší typ generátora náhodných čísiel ktorý si sami spravujete a môže zvýšiť bezpečnosť 2FA účtov alebo multipodpisových účtov"])},
    "create_vanity_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Môžete si vygenerovať vlastnú adresu účtu. Táto metóda generuje základný algorand účet a generuje veľa účtov zaradom kým sa nesplnia požiadavky na účet, npr začiatočné písmená."])},
    "create_multisign_account_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multipodpisový účet je najbezpečnejšia forma účtu. Ak sa používa s rôznymi zariadeniami a rôznymi generátormi náhodných čísel, poskytuje korporátnu bezpečnosť bez zvýšených nákladov."])},
    "watch_account_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Môžete sledovať ľubovoľný účet na sieti."])},
    "import_account_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Môžete obnoviť účet z mnemotechnickej pomôcky bežného algorand účtu."])},
    "shamir_account_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Môžete obnoviť účet z mnemotechických kódov typu shamir backup recovery."])},
    "create_first": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vytvorte si svoj prvý účet"])},
    "no_accounts_at_network": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pre vybranú sieť nemáte priradený žiadny účet. Zobrazujú sa všetky účty."])},
    "basic_options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Základné účty"])},
    "advanced_options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pokročilé možnosti"])},
    "skip_challange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preskočiť overenie"])},
    "new_account_challange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New account challange"])},
    "click_to_show_positions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click to show positions"])},
    "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["to"])}
  },
  "arc76account": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Účet z emailu a hesla (ARC-0076)"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kombinácia hashu emailu a hesla je použitá pre vygenerovanie účtu. Heslo alebo email môžete zmeniť iba pomocou rekeying metódy."])},
    "description2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ARC-0076 štandard bude použitý s algoritmom PBKDF2-999999-SHA256. Odporúčame silu hesla aby mala aspoň 50 znakov. Tento účet môže slúžiť ako alternatíva k ostatným účtom kde počítač generuje náhodné číslo a môže zvýšiť Vašu bezpečnosť. Heslo udržujte vo v prísnej tajnosti a neprepoužívajte ho."])},
    "arc_draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ARC-0076 je v stavu draftu a štandard sa môže zmeniť. Považujte aktuálnu implementáciu za experimentálnu."])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "select_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zadajte veľmi veľmi silné a náhodné heslo"])},
    "save_password_switch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uložiť heslo"])},
    "password_not_stored": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pozor! Neuložíme Vaše heslo do zabezpečeného úložiska. Uložíme tam iba email a adresu Vášho účtu. Pri každom podpisovaní transakcií budete vyzvaný na zadanie hesla."])},
    "gui_not_implemented": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GUI pre podpisovanie na základe opýtania hesla NIE je aktuálne implementované."])}
  },
  "import": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Import peňaženky"])},
    "wallet_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Názov peňaženky"])},
    "wallet_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Súbor peňaženky"])},
    "import_wallet_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importovať peňaženku"])},
    "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Môžete si importovať vyexportovanú peňaženku. Heslo ostáva rovnaké ako v predchádzajúcej peňaženke. Peňaženka sa dá exportovať v nastaveniach, kde sa dá zmeniť aj heslo."])}
  },
  "acc_type": {
    "basic_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bežný účet"])},
    "multisig_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multipodpisový účet"])},
    "public_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verejný účet"])},
    "rekeyed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rekeyed account"])},
    "ledger_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HW ledger účet"])},
    "wc_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WalletConnect account"])}
  },
  "account": {
    "select_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyberte si účet"])}
  },
  "pay": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nová platba - od"])},
    "toggle_camera": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zapnúť/Vypnúť kameru"])},
    "note_is_b64": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poznámka je v base64. Ak vložíte poznámku v base64, odošle sa ako raw data pri vytváraní transakcie."])},
    "selected_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vybraný účet"])},
    "multisig_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multipodpisový účet"])},
    "create_proposal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vytvoriť návrh"])},
    "sign_proposal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Podpísať & odoslať návrh"])},
    "signature_from_friend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zadajte sem podpis od Vášho priateľa"])},
    "load_multisig_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Načítať údaje z podpisu"])},
    "pay_to_wallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zaplatiť na účet z peňaženky"])},
    "pay_to_other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zaplatiť na iný účet"])},
    "store_other_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je lepšie si uložiť účet do zoznamu adries pred vykonaním platby"])},
    "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Čiastka"])},
    "fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poplatok"])},
    "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poznámka"])},
    "review_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Náhľad platby"])},
    "review_payment_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skontrolujte si poctivo platobné údaje. Platba na nevalidný účet sa nedá vrátiť späť."])},
    "from_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Z účtu"])},
    "pay_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zaplatiť na účet"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Celková čiastka"])},
    "transaction_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detaily transakcie"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Názov"])},
    "first_round": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prvý blok"])},
    "last_round": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Posledný blok"])},
    "genesis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GenesisID"])},
    "tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tag"])},
    "to_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Na účet"])},
    "signatures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Podpisy"])},
    "signed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Podpísaný"])},
    "not_signed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nepodpísaný"])},
    "sign_with": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Podpísať s"])},
    "sign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Podpísať"])},
    "send_to_other_signators": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pošlite ďalším podpisovateľom"])},
    "send_to_network": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odoslať transakciu do siete"])},
    "state_sending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odosielam prevod validátorom do siete"])},
    "state_sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Platba bola odoslaná do siete. Tx: "])},
    "state_waiting_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Čaká sa na potvrdenie transakcie v bloku."])},
    "state_confirmed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potvrdenie bolo prijaté. Vaša platba je v bloku č."])},
    "transaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transakcia"])},
    "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chyba"])},
    "asset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktívum"])},
    "state_error_not_sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Platba pravdepodobne nedorazila do siete. Máte prístup k internetu? Skontroolujte si stav na účte."])},
    "set_max": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nastaviť maxium"])},
    "environment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prostredie"])},
    "pay_to_address_malformed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresa príjemcu platby vyzerá byť chybná."])},
    "asset_failed_to_load": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktívum sa nepodarilo načítať"])},
    "asset_too_small_balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyzerá, že nemáte dostatočný zostatok pre vykonanie platby"])},
    "rekey_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prekľúčovať (rekey) na účet"])},
    "rekey_to_wallet_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prekľúčovať na účet v peňaženke"])},
    "rekey_to_external_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prekľúčovať na externý účet"])},
    "rekey_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prekľúčovať účet"])},
    "rekey_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prosím zvýšte pozornosť pre túto transakciu. Od podpísania tejto transakcie zmeníte oprávnenia k podpisovaniu transakcií na Vašom účte. Ak nemáte prístup k privátnemu kľúču novej adresy, nebudete môcť podpisovať ďalšie transakcie a je možné, že aktíva na účte nebude možné už nikdy použiť."])},
    "combine_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skobinujte podpisy od ostatných signatárov"])},
    "combine_action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skobninovať podpisy"])},
    "return_to_wc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naspäť do wallet connect"])},
    "select_account_for_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyberte si účet z ktorého chcete vykonať platbu"])},
    "create_multisig_proposal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Navrhnite mulitipodpisovú transakciu"])},
    "2fa_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kód dvoj úrovňovej autorizácie"])},
    "sign_arc14_request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Podpísať autorizačný request"])},
    "toggle_send_to_others_form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zapnúť/Vypnúť Formulár odoslať ostatným"])},
    "toggle_combine_with_others_form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zapnúť/Vypnúť Skombinovať podpisy"])},
    "process_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sprocesovať platbu"])},
    "tx_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ transakcie"])}
  },
  "settings": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nastavenia"])},
    "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Server"])},
    "environment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prostredie"])},
    "mainnet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mainnet"])},
    "testnet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Testnet"])},
    "devnet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reach Devnet"])},
    "sandbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sandbox"])},
    "host": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hostiteľ"])},
    "token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["token"])},
    "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jazyk"])},
    "pass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heslo k peňaženke"])},
    "oldpass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Staré heslo"])},
    "newpass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nové heslo"])},
    "repeatpass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nové heslo - kontrola"])},
    "backup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Záloha peňaženky"])},
    "backup_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Môžete si zazálohovať peňaženku alebo si ju otvoriť v inom počítači."])},
    "create_backup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vytvoriť zálohu"])},
    "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stiahnuť"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vymazať peňaženku"])},
    "strength": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sila hesla"])},
    "updated_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heslo bolo upravené"])},
    "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vlastné nastavenie serverov"])},
    "protocol_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrácia protokolu"])},
    "protocol_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zaregistrovať protokol web+algorand://"])},
    "protocol_change_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Protokol bol úspešne zaregistrovaný"])},
    "dev_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zobrazovať informácie pre vývojárov"])},
    "update_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zmeniť heslo"])}
  },
  "transaction": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detail transakcie"])},
    "tr_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID transakcie"])},
    "tr_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ transakcie"])},
    "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poznámka"])},
    "note_raw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dáta"])},
    "note_decoded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dekódovaná poznámka"])},
    "tr_close_rewards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uzavretá odmena"])},
    "closing_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uzavretá čiastka"])},
    "confirmed_round": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potvrdený blok"])},
    "fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poplatok"])},
    "first_valid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prvý platný blok"])},
    "genesis_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Genesis id"])},
    "genesis_hash": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Genesis hash"])},
    "intra_round": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offset interné čísla bloku"])},
    "last_valid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Posledný platný"])},
    "payment_tr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transakcia platby"])},
    "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Čiastka"])},
    "close_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uzavretá čiastka"])},
    "receiver": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Príjemca"])},
    "receiver_rewards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odmena pre príjemcu"])},
    "round_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Čas vytvorenia bloku"])},
    "sender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odosielateľ"])},
    "sender_rewards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odmena pre odosielateľa"])},
    "signature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Podpis"])},
    "created_asset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vytvorené aktívum"])},
    "asset_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Názov aktíva"])},
    "asset_creator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tvorca aktíva"])},
    "asset_manager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manager aktíva"])},
    "asset_reserve": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rezervný účet aktíva"])},
    "asset_freeze": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uzamykateľný účet aktíva"])},
    "asset_clawback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Účet clawback"])},
    "asset_unitName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jednotka"])},
    "asset_total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Celkový počet aktíva"])},
    "asset_decimals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Počet desatinných miest aktíva"])},
    "asset_defaultfrozen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Predvolené je zamknuté"])},
    "asset_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL adresa aktíva"])},
    "asset_metadata": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metadata aktíva"])},
    "time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Čas"])},
    "group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID skupiny transakcií"])}
  },
  "privacy": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zásady ochrany osobných údajov"])},
    "q1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aké osobné informácie o mne zhromažďujete?"])},
    "a1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Žiadne. Okrem IP adresy a prehliadača, ktorý je uložený v protokoloch pre každú internetovú službu. Nezhromažďujeme žiadnu formu súkromných informácií, ako je vaše meno, e-mail alebo adresa."])},
    "q2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Čo ukladáte do cookies? "])},
    "a2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nič. Nepoužívame cookies. Na ukladanie vašej peňaženky používame trvalé miestne úložisko s názvom indexdb."])},
    "q3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ako zhromažďujete analytické informácie?"])},
    "a3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nezhromažďujeme. Nepoužívame žiadnu formu analytických služieb, napr. Google Analytics, aby ste sa cítili bezpečnejšie. Web je čisto html s javascriptom a vašu IP adresu poznáme iba z requestov na server. V závislosti od Vašich nastavení a Vášho servera algod alebo indexer môže aj vybraný server spoznať vašu adresu IP."])},
    "q4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ovplyvňuje použitie tejto služby reklamy ktoré vidím?"])},
    "a4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie. Komerčné servery od nás nemajú žiadne informácie o žiadnom použití. Jediným spôsobom, ako môže napríklad spoločnosť Google vedieť, že túto službu používate, je prístup na tieto stránky z vyhľadávania Google."])},
    "q5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kto poskytuje túto službu?"])},
    "a5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scholtz & Company, jsa has created this service. IČO: 51 882 272, IČ DPH: SK2120828105. Ak to beží na oficiálnych webových stránkach poskytovaných spoločnosťou a-wallet.net je tiež poskytovateľom služieb. Spoločnosť nezodpovedá za žiadne služby ani chyby v aplikácii."])}
  },
  "assetcreate": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nové NTF / ASA"])},
    "show_advanced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zobraziť pokročilý formulár"])},
    "assetName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Názov aktíva"])},
    "creator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresa tvorcu"])},
    "hide_advanced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skry pokročilý formulár"])},
    "creator_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Najskôr si vytvorte adresu prosím"])},
    "default_fronzen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zmrazené: Pred uskutočnením transakcie bude potrebné zrušiť zmrazenie používateľských účtov"])},
    "decimals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desatinné miesta: celé číslo desatinných miest pre výpočet jednotky aktíva"])},
    "totalIssuance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Celkový počet aktíva k dispozícii v obehu"])},
    "unitName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Názov jednotky aktíva"])},
    "assetURL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL adresa smerujúca na miesto ktoré popisuje aktívum"])},
    "assetMetadataHash": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hash aktíva. Base64 format."])},
    "manager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manager môže upraviť adresy managera, rezervy, uzamykajúceho účtu a clawback účtu pre aktívum."])},
    "reserve": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rezervná adresa: Zadaná adresa sa považuje za rezervu majetku (nemá žiadne zvláštne privilégiá, má iba informačný charakter)"])},
    "freeze": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uzamykajúci účet: Zadaná adresa môže zmraziť alebo zrušiť zmrazenie pozícií majetku používateľa"])},
    "clawback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clawback účet: Zadaná adresa môže zrušiť držbu prostriedkov používateľa a poslať ich na iné adresy"])},
    "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poznámka uvedená v transakcii vytvorenia"])},
    "create_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vytvoriť NFT / ASA"])}
  },
  "new_account_ledger": {
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pridať HW ledger účet"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ledger is a physical device - HW wallet which can store your private key. The maximum security for storing the assets on algorand is multisig account with all signators secured by ledger devices."])},
    "last_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Posledná chyba"])},
    "connect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pripojiť ledger a načítať adresu"])},
    "slot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slot"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresa"])},
    "primary_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Primárna adresa"])},
    "account_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Názov účtu"])},
    "save_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pridať účet do peňaženky"])}
  },
  "faq": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Často kladené otázky"])},
    "q1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O peňaženke"])},
    "a1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aplikáciu peňaženky Algo pôvodne vytvorila spoločnosť Scholtz & Company, jsa. Hlavnými výhodami jeho používania je otvorený zdrojový kód, čisté html, kompatibilný s korporátnymi požiadavkami a zabezpečenie."])},
    "q2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ako sú zabezpečené moje údaje v peňaženke?"])},
    "a2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vaše údaje sú iba v prehliadači. Sú uložené v trvalom úložisku indexdb. Každá peňaženka je šifrovaná pomocou aes256 pomocou hesla peňaženky. Ak nastavíte dostatočne silné heslo, Vaša peňaženka nebude nikdy prelomená hrubou silou. Heslo neopustí váš počítač a nachádza sa iba v medzipamäti prehliadača. Po 5 minútach od prihlásenia je cache zničená."])},
    "q3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ide moje heslo k peňaženke na internet?"])},
    "a3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nikdy"])},
    "q4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ako môžem zmeniť heslo k peňaženke?"])},
    "a4_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prihláste sa a vstúpte do svojej peňaženky"])},
    "a4_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prejdite do nastavení"])},
    "a4_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zadajte staré heslo, nové heslo a potvrďte nové heslo"])},
    "a4_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odošlite formulár a vaše heslo bude zmenené"])},
    "q5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Môžem presunúť svoju peňaženku do iného počítača?"])},
    "a5_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prihláste sa a vstúpte do svojej peňaženky"])},
    "a5_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prejdite do nastavení"])},
    "a5_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prejdite do sekcie zálohovanie a uložte svoje údaje do miestneho súboru"])},
    "a5_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["V druhom počítači prejdite do sekcie nová peňaženka, importujte peňaženku zo stiahnutého súboru a nahrajte do druhého prehľadávača"])},
    "a5_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prístup do peňaženky je s rovnakým heslom ako predtým. Heslo si môžete zmeniť v nastaveniach."])},
    "q6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Môžem skontrolovať váš zdrojový kód?"])},
    "a6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Áno. https://github.com/scholtz/wallet/ Táto peňaženka bola vytvorená ako otvorený zdroj a je možné ju bezplatne používať, kopírovať alebo upravovať aj na komerčné účely. Ak sa môžete na projekte zúčastniť, buďte prosím láskaví a vytvorte pull request v oficiálnom gite so svojím vylepšením."])},
    "q7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ako môžem projektu pomôcť?"])},
    "a7_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Príspevok na algo adresu AWALLETCPHQPJGCZ6AHLIFPHWBHUEHQ7VBYJVVGQRRY4MEIGWUBKCQYP4Y pomôže projektu pokračovať. Projekt nebol financovaný z Algorand inc ani Algorand foundation."])},
    "a7_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ak chcete preložiť projekt alebo pomôcť s prekladom, prejdite na stránku https://github.com/scholtz/wallet/tree/master/src/locales, skopírujte prekladový súbor do svojho jazyka a vytvorte pull request."])},
    "a7_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ak ste programátor, skontrolujte kód alebo nám pomôžte akýmkoľvek spôsobom akým uznáte za vhodné aby bol tento projekt lepší."])},
    "q8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ako vytvoriť účet s viacerými podpismi?"])},
    "a8_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Najskôr vytvorte účty pomocou súkromných kľúčov alebo vytvorte verejné účty. Je lepšie dať im mená, ktoré si pamätáte."])},
    "a8_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prejdite na vytvorenie novej adresy a vyberte adresu s viacerými podpismi."])},
    "a8_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyberte všetky účty, ktoré môžu podpisovať transakcie, a vyberte počet podpisov, ktoré sú minimálne na to, aby sieť mohla akceptovať multipodpisovú platbu."])},
    "a8_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Po vytvorení účtu s viacerými podpismi k nemu získate prístup zo stránky so zoznamom účtov."])}
  },
  "connect": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pripojenie k decenralizovanej aplikácii použitím WalletConnect"])},
    "uri": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WalletConnect URI"])},
    "connect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pripojiť"])},
    "disconnect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odpojiť"])},
    "sendBack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odoslať spať do aplikácie"])},
    "reject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zamietnuť"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresa"])},
    "authaddr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autorizačná adresa"])},
    "index": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Index"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ"])},
    "from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Od"])},
    "asset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktívum"])},
    "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Čiastka"])},
    "rekeyto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zmena autorizačnej adresy"])},
    "method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Method"])},
    "client_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID klienta"])},
    "request_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID requestu"])},
    "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zruš"])},
    "peer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peer"])},
    "connected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pripojené"])},
    "sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Relácia"])},
    "requests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Requesty"])},
    "fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poplatok"])},
    "total_fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Celkový poplatok"])},
    "sign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Podpísať"])},
    "clipboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pripojiť zo schárnky"])},
    "or": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["alebo"])},
    "sign_txs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Podpíšte každú transakciu"])},
    "signed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Podpísané"])},
    "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Príjemca"])},
    "validity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validita"])},
    "rounds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["blokov"])},
    "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poznámka"])},
    "group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID skupiny transakcií"])},
    "app": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID aplikácie"])},
    "app_args": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Argumenty aplikácie"])},
    "app_accounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Účty aplikácie"])},
    "app_assets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID aktív aplikácie"])},
    "boxes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Boxy"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Názov"])},
    "genesis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Genesis ID"])},
    "genesis_hash": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Genesis Hash"])},
    "session_proposals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Návrhy pre reláciu"])},
    "toggle_camera": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zapnúť/Vypnúť kameru"])},
    "init_wc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vytvoriť spojenie na Wallet Connect"])},
    "sign_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Podpísať všetky"])},
    "proposer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Navrhovateľ"])},
    "all_accounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Použiť všetky účty"])}
  },
  "new_account_wc": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pridať WalletConnect účet"])},
    "account_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Názov účtu"])},
    "last_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Posledná chyba"])},
    "scan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nascanovať QR kód"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresa"])},
    "save_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uložiť adresu do peňaženky"])},
    "copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skopírovať URI do schránky"])},
    "show_qr_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show QR code"])}
  },
  "new_account_2fa": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Účet dvojúrovňovej autorizácie"])},
    "help1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Účet dvojúrovňovej autorizácie Vám umožní vytvoriť multipodpisový účet s 3mi účtami s 2mi podpismi vyžadujúcimi oprávnenie vykonať transakciu."])},
    "help2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prvý účet môže byť štandadný účet uložený v peňaženke. Druhý účet je api účet dvoj-úrovňovej autorizácie ktorý po overení totožnosti prvého účtu a zadaním správneho kódu môže podpísať ľubovoľnú transakciu. Tretí účet považujte za účet pre obnovenie prístupu."])},
    "help3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ak stratíte prístup k prvému účtu, alebo služba dvojúrovňovej autorizácie nebude fungovať, môžete prekľúčovať váš účet na ďalší účet a naďalej disponovať prostriedkami. Používaním dvojúrovňovej autorizácie zvyšujete bezbečnosť uloženia primárneho účtu. Pre maximálnu bezpečnosť účet pre obnovenie nemajte nikdy v online priestore."])},
    "2fa_provider": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poskytovateľ dvojúrovňovej autorizácie"])},
    "hot_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Primárny účet"])},
    "cold_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Účet pre obnovenie prístupu"])},
    "request_qr_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyžiadať QR kód dvojúrovňovej autorizácie"])},
    "scan_qr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nascanujte QR kód s Vašou 2FA aplikáciou"])},
    "manual_entry_key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kľúč pre manuálne zadanie"])},
    "confirm_2fa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potvrdiť nastavenie"])},
    "confirm_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zadajte kód dvojúrovňovej autorizácie. Po tomto potvrdení, nebude možné pre kombináciu primárneho účtu a účtu pre obnovenie zobraziť tieto nastavenia."])},
    "2fa_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kód dvoj úrovňovej autorizácie"])},
    "save_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uložiť účet"])}
  },
  "onlineofflinedialog": {
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ochraňujte blockchain Vašim účtom"])},
    "warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Môžete si vybrať server ktorý bude Vašim účtom ochraňovať sieť. Server vygeneruje tajné kľúče ktorými bude ochraňovať sieť a vašim účtom podpíšete transakciu kde oprávnite tými tajnými kľúčami ochraňovať sieť. Ak máte na účte aspoň 30000 algo, a zaplatíte poplatok 2 algo za registráciu do stakingu, pri každom novom bloku ktorý vytvorí Váš delegovaný server zarobíte staking odmenu ktorá je zložená z vyzbieraných poplatkov z transakcií a z ďalších prostriedkov pre incentivizáciu stakingu."])},
    "makeOnline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nastaviť ochranu siete"])},
    "makeOffline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vypnúť ochranu siete"])},
    "host": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Algod server"])},
    "fromclipboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sprocesovať dáta zo schránky"])},
    "stakingRegistration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zaregistrovať pre staking - 2A tx poplatok"])},
    "stakingHelp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zaplatením 2A a uchovaním zostatku na účte cez 30000 algo získate staking odmeny. Pri každom novom bloku ktorý vytvorý delegovaný server na Váš účet príde staking odmena."])},
    "onlineRounds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Počet blokov s aktívnou ochranou siete"])}
  },
  "new_account_shamir": {
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obnova účtu pomocou shamir backup"])},
    "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shamir backup je metóda ktorou sa môže privátny kľúč bezpečne rozdiliť na niekoľko časti s ďalším nastavením minimálneho počtu častí pre obnovu."])},
    "mnemonic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mnemonic"])},
    "add_mnemnic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pridať mnemonic"])},
    "account_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Názov účtu"])},
    "recover": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obnoviť"])}
  },
  "account_export": {
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Export účtu"])},
    "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Na tejto stránke môžete exportovať svoj privátny kľúč ku Vášmu účtu pomocou mnemotechnickej pomôcky od algorandu alebo pomocou vygenerovania niekoľkých kľúčov pre obnovu - shamir backup."])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zadajte heslo k Vašej peňaženke"])},
    "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pokračovať"])},
    "algo_mnemonic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Algorand mnemonic"])},
    "shamir_backup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shamir backup"])},
    "toggle_qr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zapnúť/Vypnúť QR code"])},
    "recovery_threshold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Počet častí pre obnovu"])},
    "number_of_mnemonics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rozdeliť na počet častí"])},
    "generate_shamir": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vygenerovať shamir backup časti"])},
    "previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Predchádzajúca časť"])},
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ďalšia čast"])},
    "index": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Časť"])},
    "shamir_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uložte si mnemotechnické pomôcky na veľmi bezpečných miestach. Uistite sa, že máte všetky časti správne zapísané a že v jednom mieste nebudete držať viac ako počet častí pre obnovu."])},
    "algo_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uložte si mnemotechnickú pomôcku na veľmi bezpečnom mieste."])},
    "dev_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informácie pre developerov"])}
  },
  "arc200": {
    "optin_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pridať ARC 200 aktívum na účet"])},
    "app_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ARC200 ID"])},
    "fetch_action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Načítať údaje o aktíve"])},
    "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset"])},
    "examples": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Príklady"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Názov"])},
    "symbol": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Symbol"])},
    "decimals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desatinné čísla"])},
    "supply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Celková zásoba"])},
    "balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zostatok na účte"])},
    "is_opted_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Účet môže prijať aktívum"])},
    "create_box": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vytvoriť box pre toto aktívum pre tento účet"])},
    "save_action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uložiť aktívum na účet"])}
  },
  "scheduled_payments": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scheduled payments management"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["With decentralized scheduler you will deploy the smart contract - escrow account which will be used for purpose to allow any executor perform withdrawal from your escrow account to the destination account when the time comes. Executors are incentivized to execute your scheduled task with vision of receiving small fee paid in ASA.Gold token - 0.001 gram of gold."])},
    "new_scheduled_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New scheduled payment"])},
    "period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Period"])},
    "pay_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pay to"])},
    "send_asset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asset to send"])},
    "create_escrow_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create escrow account"])},
    "list_of_payments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List of active payments"])},
    "no_payment_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No scheduled payments has been found"])},
    "app_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Escrow app id"])},
    "app_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Escrow app address"])},
    "start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start"])},
    "fee_balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fee balance"])},
    "execution_fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Execution fee"])},
    "description_detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In this detail overview of the scheduled payment you can see the current asset balance of the escrow account and you have actions to deposit more assets to the escrow account or to executors."])},
    "details_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scheduled payment details"])},
    "seconds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["seconds"])},
    "deposit_fee_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deposit asa.gold token to fee pool smart contract"])},
    "fee_asset_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Execution fee token"])},
    "amount_to_deposit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount to deposit to fees"])},
    "get_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get more tokenized gold"])},
    "list_of_assets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List of assets at escrow account"])},
    "assets_loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asset information is loading"])},
    "asset_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asset ID"])},
    "asset_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asset name"])},
    "asset_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asset amount"])},
    "deposit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deposit"])},
    "optin_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Escrow account optin action"])},
    "optin_to_asset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opt in to asset"])},
    "optin_click": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opt in"])},
    "withdraw_asset_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Withdraw from escrow account"])},
    "withdraw_asset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asset to withdraw"])},
    "withdraw_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount to withdraw"])},
    "withdraw_click": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Execute withdrawal"])},
    "load_script_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Load app execution profile"])},
    "load_click": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Load"])},
    "xgov_promo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This work has been performed with support from the Algorand Foundation xGov Grants Program"])}
  }
}